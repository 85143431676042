import React from 'react';

import "./spinner.scss"

const Spinner = () => {
 return (
  <div className="loader_parent">
   <div class="sk-chase">
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
   </div>
  </div>
 );
};

export default Spinner;

