export const handleError = (status, data) => {
  switch (status) {
    case 200:
    case 201:
      return data
    case 400:
    case 401: {
      const err = new Error(
        data.message || 'Your session is timeout. Please login again.'
      )
      throw err
    }
    case 404:
      throw new Error(
        (data.err && data.err.message) || data.message || 'Not Found'
      )
    case 405:
      throw new Error(
        (data.err && data.err.message) || data.message || 'Method Not Allowed'
      )
    case 500:
      throw new Error(
        (data.err && data.err.message) ||
          data.message ||
          'Whoops! Sorry something went wrong on our end. Please try again!.'
      )
    default:
      throw new Error(
        'Whoops! Sorry something went wrong on our end. Please try again!.'
      )
  }
}
