import { BOOKING_INFORMATION_REDUCER } from '../../constant/constant'

export const setInformation =
  (key, data) => async (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: BOOKING_INFORMATION_REDUCER.SET_DATA,
      payload: data,
      key,
    })
  }
export const setOtherService = (key, data) => async (dispatch, getState, thunkDependencies) => {
  dispatch({
    type: BOOKING_INFORMATION_REDUCER.SET_OTHER_SERVICE,
    payload: data,
    key,
  })
}

export const submit = () => async (dispatch, getState, thunkDependencies) => {
  dispatch({
    type: BOOKING_INFORMATION_REDUCER.VALIDATE_ALL,
  })
}

export const setRedirect =
  (value) => async (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: BOOKING_INFORMATION_REDUCER.SET_REDIRECT,
      payload: value,
    })
  }

export const setCarBooking = id => async (dispatch, getState, thunkDependencies) => {
  dispatch({
    type: BOOKING_INFORMATION_REDUCER.SET_DATA,
    payload: id,
    key: 'carId',
  })
}

export const setStatusStep =
  (key, value) => async (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: BOOKING_INFORMATION_REDUCER.SET_STEP_STATUS,
      key,
      value
    })
  }
