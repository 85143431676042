/** @format */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faLine } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

import './footer.scss';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constant/ROUTES';
import { useTranslation } from 'react-i18next';
import { Payment } from '../../data';

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = localStorage.getItem('LANGUAGE')
    ? localStorage.getItem('LANGUAGE')
    : null;
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer_container'>
          <div className='footer_col'>
            <div className='footer_logo'>
              <img src='/image/logoHeader.png' alt='logo_bookvipcar' />
            </div>
            <div className='footer_contact'>
              <div className='footer_contact-item'>
                <p style={{ textTransform: 'uppercase', marginBottom: 10 }}>
                  {t('Company')} {lang && lang === 'vn' && 'TNHH'} AITRAVEL
                </p>
                <p style={{ marginBottom: 10 }}>
                  {t('Tax code')}: 0106819159{' '}
                  {t('issued by Hanoi Department of Planning and Investment')}
                </p>
                <p>
                  {t('No 9B, 13 alley, 139 lane, Tan Mai Street, Tan Mai ward')}
                  <br />
                </p>
                <p>{t(' Hoang Mai district, Hanoi city, Vietnam')}</p>
              </div>
              <div className='footer_contact-item'>
                <a href='tel: 0968731699'>
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ marginRight: 10, color: '#354167' }}
                  />{' '}
                  +8496-873-1699
                </a>
              </div>
              <div
                style={{ display: 'flex', alignItem: 'center' }}
                className='footer_contact-item'
              >
                <a href='mailto: admin@gmail.com'>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ marginRight: 10, color: '#354167' }}
                  />{' '}
                  eigyou@aitravelgroup.com
                </a>
              </div>
            </div>
            <div className='footer_social'>
              <a
                href='https://timeline.line.me/user/_dY9V10jh_DCx0eMq4_gJRrIsHnXFuqfj1BOLuWA'
                target='_Using target='
                rel='search'
                className='footer_social-item'
              >
                <FontAwesomeIcon icon={faLine} />
              </a>
              <a
                className='footer_social-item'
                href='https://www.facebook.com/profile.php?id=100063679657554'
                target='_Using target='
                rel='search'
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
              {/* <div className="footer_social-item">
                <FontAwesomeIcon icon={faInstagramSquare} />
              </div> */}
            </div>
          </div>
          <div className='footer_col'>
            <div className='footer_page'>
              <p
                className='footer_page-link'
                onClick={() => navigate(ROUTES.HOME)}
              >
                {t('Home')}
              </p>
              <p
                className='footer_page-link'
                onClick={() => navigate(ROUTES.CARS)}
              >
                {t('Cars')}
              </p>
              {/* <p className="footer_page-link" onClick={() => navigate(ROUTES.SERVICE)}>
                {t('Service')}
              </p>
              <p className="footer_page-link" onClick={() => navigate(ROUTES.CONTACT)}>
                {t('Contact')}
              </p> */}
              <p
                className='footer_page-link'
                onClick={() => navigate(ROUTES.RULES)}
              >
                {t('Rules')}
              </p>
            </div>
          </div>
          <div className='footer_col'>
            <p className='footer_payHeading text_l'>{t('Payment')}</p>
            <ul className='footer_payList'>
              {Payment.map((item) => (
                <li key={item.name} className='footer_payItem'>
                  <img src={item.image} alt='' />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <p className='footer_copyright text_s'>
          Copyright © Viet Japan Digital | All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
