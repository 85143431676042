import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from './Button'

import './ButtonFilter.scss'
import { useTranslation } from 'react-i18next'
const ButtonFilter = ({ seats, image, onClick, otherClass, disabled, selected }) => {
  const [isClicked, setIsClicked] = useState(false)
  const { t } = useTranslation()
  const disabledClass = disabled ? 'disabled' : ''

  useEffect(()=> {
    if (selected.length && selected.includes(seats)) {
      setIsClicked(true)
    }
    return () => {
      setIsClicked(false)
    }
  }, [selected, seats])
  return (
    <button
      className={`button_filter ${isClicked ? 'button_isChecked' : ''
        }  ${otherClass} ${disabledClass}`}
      onClick={() => {
        setIsClicked(!isClicked)
        onClick(seats)
      }}
    >
      {/* <div className='button_filter-image'>
        <img src={image} alt='car' />
      </div> */}
      <p className='text_m'>{t('{{ seat }} Seats', { seat: seats })}</p>
    </button>
  )
}

ButtonFilter.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

ButtonFilter.defaultProps = {
  text: '',
  onClick: () => { },
}

export default ButtonFilter
