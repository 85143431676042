import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { faCar, faCouch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatMoney } from "../../helpers/common";


import "./cardDetail.scss"
import { useTranslation } from "react-i18next";
const CardDetail = ({ name, image, price, seats, transmission, desc,brand, containerStyle }) => {
  const { t } = useTranslation()
  return (
    <div className='card_detail'>
      <div className='card_detail_content'>
        <p className='card_detail_content-name text_heading'>{brand}</p>
        <div className='card_detail_content-image'>
          <img src={image} alt={name} />
        </div>
        <p className='card_detail_content-price text_heading'>
          {formatMoney({ value: price, locales: 'vi-VN' }) + ' VND'}
          <span> / Day</span>
        </p>
      </div>
      <div className='card_detail_desc'>
        <h6 className='card_detail_desc-heading text_heading'>
          {t('Specifications')}
        </h6>
        <p className='card_detail_content-seat text_l'>
          <FontAwesomeIcon icon={faCouch} /> {t('{{ seat }} Seats', { seat: seats })}
        </p>
        <p className='card_detail_content-transmission text_l'>
          <FontAwesomeIcon icon={faCar} /> {t(transmission)}
        </p>
        <p className='card_detail_content-desc text_l'>{desc}</p>
      </div>
    </div>
  )
}

CardDetail.propTypes = {
  containerStyle: PropTypes.object,
};

CardDetail.defaultProps = {
  containerStyle: {
    borderTop: '1px solid #eeeeee'
  },
};
export default CardDetail
