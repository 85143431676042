import { BOOKING_REDUCER } from '../../constant/constant'

export const fetch =
 (id) => async (dispatch, getState, thunkDependencies) => {
  const res = await thunkDependencies.car.getBooking(id)
  dispatch({
   type: BOOKING_REDUCER.SET_DETAIL,
   payload: res.data[0],
  })
 }

export const updateBookingPaid =
 (id, vnp_ResponseCode, vnp_OrderInfo) => async (dispatch, getState, thunkDependencies) => {
  const res = await thunkDependencies.car.updateBookingPaid({ id, vnp_ResponseCode, vnp_OrderInfo })
 }

export const payment =
 (id, totalPrice) => async (dispatch, getState, thunkDependencies) => {
  console.log('totalPrice', totalPrice)
  try {
   const res = await thunkDependencies.car.paymentAgain({ bookingID: id, vnp_Amount: totalPrice })
   return res;
  } catch (err) {
  }
 }

export const cancel =
 (id) => async (dispatch, getState, thunkDependencies) => {
  try {
   const res = await thunkDependencies.car.cancelPayment(id)
  } catch (err) {
  }
 }
