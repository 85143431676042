import React from 'react'

import './style.scss'
import Button from '../../components/Button/Button'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '../../constant/ROUTES'

function NotfoundContainer(props) {
  return (
    <div className="container">
      <div className="col-sm-10 col-sm-offset-1  text-center">
        <div className="four_zero_four_bg">
          <h1 className="text-center ">404</h1>
        </div>
        <div className="contant_box_404">
          <h3 className="text_heading" style={{ textAlign: 'center' }}>
            Look like you're lost
          </h3>
          <p style={{ textAlign: 'center' }}>The page you are looking for not avaible!</p>
          <NavLink
            to={ROUTES.HOME}
          >
            <Button text='Go home' otherClass={'link_404'} />
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default NotfoundContainer
