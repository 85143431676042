import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import './selectDate.scss'

const SelectDate = ({
  onChange,
  otherClass,
  label,
  name,
  disabled,
  onOk,
  defaultValue,
  disabledDate,
  error,
  touched,
}) => {
  const { t } = useTranslation()
 
  return (
    <div className='search_location-item'>
      <label className='text_m'>
        {t(label)}
        <span style={{ color: 'red' }}>*</span>
      </label>
      <div className='hero_search-pickup'>
        <DatePicker
          allowClear={false}
          format='MM/DD/YYYY HH:mm'
          disabledDate={disabledDate}
          className={`${otherClass}`}
          showTime
          name={name}
          onChange={onChange}
          onOk={onOk}
          disabled={disabled}
          placeholder={t('Select date')}
          value={defaultValue ? moment(defaultValue) : null}
        />
      </div>
      {touched && touched[name] && error && error[name] && (
        <>
          {/* <div className='errorMessage'>{err}</div>  */}
          <div className='errorMessage'>{error[name]}</div> 

        </>

  )
}
  </div>
)
}

SelectDate.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

SelectDate.defaultProps = {
  label: '',
  onClick: () => { },
}

export default SelectDate
