import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import { ROUTES } from "../../../constant/ROUTES";
import { faCarRear, faHome, faPhoneVolume, faClipboard } from "@fortawesome/free-solid-svg-icons";


const variants = {
 open: {
  transition: { staggerChildren: 0.07, delayChildren: 0.2 }
 },
 closed: {
  transition: { staggerChildren: 0.05, staggerDirection: -1 }
 }
};

export const Navigation = ({ isOpen }) => {
 const itemIds = [{ title: 'Home', route: ROUTES.HOME, icon: faHome }, { title: 'Cars', route: ROUTES.CARS, icon: faCarRear }, { title: 'Rules', route: ROUTES.RULES, icon: faClipboard }, { title: '096-873-1699', route: '', icon: faPhoneVolume }];

 console.log('itemIds', itemIds)
 return (
  <motion.ul variants={variants} style={{ backgroundColor: isOpen ? '#d2dce2' : '' }}>
   <div className='header_logo'>
    <img src='/image/logo.png' alt='logo_bookvipcar'></img>
   </div>
   {itemIds.map((item, index) => (
    <MenuItem item={item} i={index} />
   ))}
  </motion.ul>
 )
}
