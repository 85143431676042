import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import './checkbox.scss'

const Checkbox = ({
  id,
  label,
  value,
  name,
  type,
  onChange,
  disabled,
  checked,
}) => {
  const { t } = useTranslation()
  return (
    <div className='checkboxItem'>
      <input
        id={id}
        type={type}
        checked={checked}
        onChange={onChange}
        value={value}
        name={name}
        disabled={disabled}
      />
      <label className={`text_m`} htmlFor={id}>
        {t(label)}
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

Checkbox.defaultProps = {
  type: 'checkbox',
  label: 'abc',
  onChange: () => {},
}

export default Checkbox
