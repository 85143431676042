/* eslint-disable import/no-anonymous-default-export */
/** @format */

import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import apis from '../apis'

import rootReducer from '../reducers'
import { thunkWithApiHandlerMiddleware } from '../helpers/middleware'

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const preloadedState = {}
let reduxStore

export const getStore = () => reduxStore

export default (history) => {
  const persistedReducer = combineReducers({
    ...rootReducer,
    router: connectRouter(history),
  })
  const middlewareEnhancer = applyMiddleware(
    routerMiddleware(history),
    thunkWithApiHandlerMiddleware(apis)
  )
  const composedEnhancers = composeEnhancers(middlewareEnhancer)

  reduxStore = createStore(persistedReducer, preloadedState, composedEnhancers)
  return reduxStore
}
