import { combineReducers } from 'redux'
import { SEARCH_REDUCER } from '../constant/constant'
import moment from 'moment'

const initialValue = {
  location: '',
  startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
  endDate: moment().add(7, 'd').format('YYYY-MM-DD HH:mm:ss'),
  seats: [],
  price: 0,
  brand: '',
  transmission: '',
  provinces: 1,
  fetching: false,
}

export const searchReducer = (state = initialValue, action) => {
  switch (action.type) {
    case SEARCH_REDUCER.SET_TRANSMISSION:
      return {
        ...state,
        transmission: action.payload,
      }
    case SEARCH_REDUCER.SET_BRAND:
      return {
        ...state,
        brand: action.payload,
      }
    case SEARCH_REDUCER.SET_PRICE:
      return {
        ...state,
        price: action.payload,
      }
    case SEARCH_REDUCER.SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      }
    case SEARCH_REDUCER.SET_PICK_UP_DATE:
      return {
        ...state,
        startDate: action.payload,
      }
    case SEARCH_REDUCER.SET_RETURN_DATE:
      return {
        ...state,
        endDate: action.payload,
      }

    case SEARCH_REDUCER.SET_SEATS:
      return {
        ...state,
        seats: action.payload,
      }

    case SEARCH_REDUCER.RESET_SEATS:
      return {
        ...state,
        seats: [],
      }
    case SEARCH_REDUCER.SET_PROVINCES:
      return {
        ...state,
        provinces: action.payload,
      }
    case SEARCH_REDUCER.RESET_SEARCH:
      return {
        ...initialValue
      }
    case SEARCH_REDUCER.FETCHING:
      return {
       ...state,
        fetching: action.payload,
      }
    default:
      return state
  }
}

export default combineReducers({
  search: searchReducer,
})
