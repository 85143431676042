export const CarData = [
  {
    id: 1,
    name: 'Honda Civic',
    brand: 'Honda',
    image: '/image/hondacivic.png',
    seat: 5,
    transmission: 'Auto',
    price: 1000000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
  {
    id: 2,
    name: 'Toyota bla bla',
    brand: 'Toyota',
    image: '/image/toyotabla.png',
    seat: 7,
    transmission: 'Auto',
    price: 1500000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
  {
    id: 3,
    name: 'Mercedes G63',
    brand: 'Mercedes',
    image: '/image/g63.png',
    seat: 8,
    transmission: 'Auto',
    price: 200000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
  {
    id: 4,
    name: 'Mercedes C63',
    brand: 'Mercedes',
    image: '/image/C63.png',
    seat: 2,
    transmission: 'Auto',
    price: 200000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
  {
    id: 5,
    name: 'Toyota 16 Seat',
    brand: 'Toyota',
    image: '/image/16seat.png',
    seat: 16,
    transmission: 'Auto',
    price: 1700000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
  {
    id: 6,
    name: 'Porsche 911',
    brand: 'Porsche',
    image: '/image/porsche.png',
    seat: 2,
    transmission: 'Auto',
    price: 300000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
  {
    id: 7,
    name: 'Audi A8',
    brand: 'Audi',
    image: '/image/audi.png',
    seat: 4,
    transmission: 'Auto',
    price: 300000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
  {
    id: 8,
    name: 'Ford Dodge',
    brand: 'Ford',
    image: '/image/dodge.png',
    seat: 4,
    transmission: 'Auto',
    price: 300000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
  {
    id: 9,
    name: 'Ford Dodge 2',
    brand: 'Ford',
    image: '/image/dodge.png',
    seat: 4,
    transmission: 'Auto',
    price: 300000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
  {
    id: 10,
    name: 'Ford Dodge 3',
    brand: 'Ford',
    image: '/image/dodge.png',
    seat: 4,
    transmission: 'Auto',
    price: 300000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
]

export const SeatsCars = [
  {
    id: 1,
    name: 'Honda Civic',
    brand: 'Honda',
    image: '/image/hondacivic.png',
    seat: 4,
    transmission: 'Auto',
    price: 1000000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
  {
    id: 2,
    name: 'Toyota bla bla',
    brand: 'Toyota',
    image: '/image/toyotabla.png',
    seat: 5,
    transmission: 'Auto',
    price: 1500000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
  {
    id: 3,
    name: 'Mercedes G63',
    brand: 'Mercedes',
    image: '/image/g63.png',
    seat: 7,
    transmission: 'Auto',
    price: 200000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
  {
    id: 4,
    name: 'Mercedes C63',
    brand: 'Mercedes',
    image: '/image/C63.png',
    seat: 8,
    transmission: 'Auto',
    price: 200000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
  {
    id: 4,
    name: 'Mercedes C63',
    brand: 'Mercedes',
    image: '/image/C63.png',
    seat: 16,
    transmission: 'Auto',
    price: 200000,
    desc: 'In Singapore, the eleventh-generation Civic was launched on 12 August 2021, utilising a 1.5-liter L15BJ turbocharged engine with a power output of 129 PS (127 hp; 95 kW) at 5,500 to 6,000 rpm and 180 N⋅m (18.4 kg⋅m; 133 lb⋅ft) of torque from 1,700 to 4,500 rpm to meet the Vehicle Quota System (VQS) band',
  },
]
export const BrandCarData = [
  {
    id: 1,
    name: 'Toyota',
    image: '/image/toyota.png',
  },
  {
    id: 2,
    name: 'Honda',
    image: '/image/honda.png',
  },
  {
    id: 3,
    name: 'Lexus',
    image: './image/lexus.png',
  },
  {
    id: 4,
    name: 'Mer',
    image: './image/mer.png',
  },
  {
    id: 5,
    name: 'Marda',
    image: './image/marda.png',
  },
  {
    id: 6,
    name: 'Nissan',
    image: './image/nissan.png',
  },
]
export const CarType = [
  {
    id: 1,
    seats: '5',
    image: '/image/toyota.png',
  },
  {
    id: 2,
    seats: '7',
  },
  {
    id: 3,
    seats: '8',
  },
  {
    id: 4,
    seats: '16',
  },
]

export const OtherService = [
  { id: '1', name: 'Souvenir', key: 'souvenir' },
  { id: '2', name: 'Hotel Reservation', key: 'hotelReservation' },
  { id: '3', name: 'Golf Reservation', key: 'golfReservation' },
  { id: '4', name: 'Translator', key: 'translation' },
  { id: '5', name: 'Massage', key: 'massage' },
  { id: '6', name: 'Japanese Karaoke', key: 'japaneseKaraoke' },
]

export const Introduce = [
  { id: '1', label: 'Ho Chi Minh', image: '/image/benthanh.jpg' },
  { id: '2', label: 'Ha Long', image: '/image/halong.jpg' },
  { id: '3', label: 'Da Nang', image: '/image/danang.jpg' },
  { id: '4', label: 'Ha Giang', image: '/image/hagiang.jpg' },
]

export const Payment = [
  { id: '1', name: 'VN Pay', image: '/image/vnp.png' },
  { id: '2', name: 'Visa', image: '/image/visa.png' },
  { id: '3', name: 'Master Card', image: '/image/mastercard.png' },
  { id: '4', name: 'JCB', image: '/image/jcb.png' },
]

