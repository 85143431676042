import * as React from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { ROUTES } from "../../../constant/ROUTES";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const variants = {
 open: {
  y: 0,
  opacity: 1,
  transition: {
   y: { stiffness: 1000, velocity: -100 }
  }
 },
 closed: {
  y: 50,
  opacity: 0,
  transition: {
   y: { stiffness: 1000 }
  }
 }
};

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];

export const MenuItem = ({ item, i }) => {
 const style = { border: `2px solid ${colors[i]}` };
 const { t } = useTranslation()
 return (
  <motion.li
   variants={variants}
   whileHover={{ scale: 1.1 }}
   whileTap={{ scale: 0.95 }}
  >
   <div className="icon-placeholder" style={style}>
    <FontAwesomeIcon icon={item.icon}/>
   </div>
   <div className="text-placeholder" style={style}>
    {item.title === '096-873-1699' ? (
     <a href="tel: 0968731699">096-873-1699</a>
    ) : (
     <NavLink
      to={item.route && item.route}
     >
      {t(`${item.title}`)}
     </NavLink>
    )
    }

   </div>
  </motion.li>
 );
};