import React from 'react';
import { Collapse } from 'antd';
import { useEffect } from 'react';
import { useLocation, } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


import './rules.scss'


const { Panel } = Collapse;

const RulesContainer = () => {
  const { t } = useTranslation()

  const location = useLocation()

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }, [location])

  return (
    <main className='rules'>
      <div className='container'>
        <div className="rules_container">
          <h1 className='rules_heading text_heading'>{t('For first-time users')}</h1>
          <p className='rules_title text_l'>{t('This page explains how to make a reservation, payment method, cancellation, terms of use, protection of privacy, etc. If you are using it for the first time, please read it.')}
          </p>
          <div className="rules_collapse">
            <Collapse defaultActiveKey={['1']} >
              <Panel header={t('Reservation method')} key="1">
                <p>{t('Book your trip to Vietnam easily online! You can create your own trip, incorporate tours into free-action days on package tours or individual travel schedules, book hotels that fit your budget, and arrange air tickets.')} {t('Feel free to use Ai Travel for tours, hotel reservations, etc.')}
                  <br />
                  {t('After completing the reservation application, we will send you a confirmation email. If you do not receive a receipt confirmation email within 24 hours, there is a possibility that you have entered your application email address incorrectly.')} {t('If you do not receive a receipt confirmation email after applying for a reservation, please contact us via email eigyou@aitravelgroup.com.')}
                  <br />
                  {t('Alternatively, you can book by calling our hotline.')}
                  <br />
                  {t('Hotline number')}：（＋８４）９６‐８７３１‐６９９
                  <br />
                  {t('If you live in Hanoi, we will consult and make a reservation at our company.')}
                  <br />
                  {t('Ai Travel Co., Ltd. Address')}：
                  <br />
                  No 9B, 13 alley, 139 lane, Tan Mai Street, Tan Mai ward, Hoang Mai district, Hanoi city, Vietnam.
                  </p>
              </Panel>
              <Panel header={t('Payment method')} key="2">
                <p>１.{t('Bank Transfer: Via Vietnam Dong or USD.')}
                {(' ')}
                  {t('Bank account name')}:AITRAVEL COMPANY (Aitravel Co. Ltd). {(' ')}
                  {t('Company address')}: No 9B, 13 alley, 139 lane, Tan Mai Street, Tan Mai ward, Hoang Mai district, Hanoi city, Vietnam.
                  {(' ')}
                  {t('Bank account number')}:
                  225255558888 ( VND)
                  18677367 ( USD).
                  {(' ')}
                  {t('Bank name')}:  Asia Commercial Joint Stock Bank-Tran Quoc Hoan branch. {(' ')}
                  {t('Bank address')}: 107 Nguyen Phong Sac street, Dich Vong Hau ward, Cau Giay district, Ha Noi city, Vietnam.
                  Swift Code: ASCBVNVX.
                  <br />
                  ２.{t('Cash payment: VND/USD or Japanese Yen.')} {(' ')}
                  {t('Sorry to trouble you, but please contact us.')}
                  <br />
                  ３.{t('Payment by credit card: according to Vietnamese dong. However, bank charges will be 3% of the total. Sorry for the inconvenience, but please contact us.')}</p>
              </Panel>
              <Panel header={t('Cancel')} key="3">
                <p>{t('The customer can cancel the travel contract by paying the cancellation fee specified below. The date of cancellation shall be the day on which the customer requests cancellation during business hours of the Company (9:00 am to 6:00 pm).')}
                  {(' ')} {t('Day tour')}
                  <br />
                  {t('30% until 10:00 the day before departure')}
                  <br />
                  {t('From 12:00 to 19:00 the day before departure: 50%')}
                  <br />
                  {t('If you do not participate after 19:00 the day before departure or without prior notice: 100%')}
                  <br />
                  {t('1 night, 2 nights tour')}
                  <br />
                  {t('30 days before departure: 10%')}
                  <br />
                  {t('25-29 days before departure: 20%')}
                  <br />
                  {t('15-24 days before departure: 30%')}
                  <br />
                  {t('7-14 days before departure: 50%')}
                  <br />
                  {t('3-6 days before departure: 75%')}
                  <br />
                  {t('Within 2 days of departure: 100%')}</p>
              </Panel>
              <Panel header={t('Business hours')} key="4">
                <p>{t('Mon-Fri 09:00-18:00 [Japan time 11:00-20:00]')}
                </p>
              </Panel>
              <Panel header={t('Terms of service')} key="5">
                <p>{t('All customers who use the mail order operated by Ai Travel (hereinafter referred to as our company) agree to the following terms of use.')}
                  <br />
                  {t('Please carefully consider the product before placing an order.・Your reservation application will be valid only if we accept it by the method specified by us.')}
                  <br />
                  {t('It will not be valid if we cannot accept it due to system trouble or other reasons.')}
                  <br />
                  {t('・Although the information on this site is regularly updated, we do not guarantee its accuracy.')}
                  <br />
                  {t('We do not assume any liability to you or any third party for any errors or omissions on this site. We may change, update, or delete the information on this site out of necessity.')}
                  <br />
                  {t('・If a problem that cannot be resolved by this agreement arises regarding the use of this service, we will discuss in good faith with the user and resolve it. In the event that a lawsuit arises regarding the use of this service, the Hanoi City Court in Vietnam shall be the exclusive jurisdictional court of the first instance.')}
                  <br />
                  2．{t('Additional Notes')}
                  <br />
                  {t('・We may change this agreement at any time without prior or subsequent notice to the customer.')}
                  <br />
                  {t('・After the revision, the content will be revised, so please check the latest content regularly.')}</p>
              </Panel>
              <Panel header={t('Privacy policy')} key="6">
                <p>{t('Ai Travel will not use personal information for purposes other than those for which the customer has given consent. We will not entrust or provide the personal information of customers who have made a reservation to a third party, except when we have received consent from the customer, or when we have received a legally binding request from a judicial or administrative agency based on laws and ordinances. . In addition, even if we have obtained your consent, when disclosing, entrusting, or providing personal information to a third party other than our company, we will make an agreement with the third party regarding the protection of personal information, and We will do our best to protect it.')}
                  <br />
                  {t("In order to protect the personal information entrusted to us by our customers from loss, destruction, unauthorized external leakage, falsification, and unauthorized access, we will establish internal regulations and take rational safety measures. In addition, after the customer has achieved the purpose of use on this site, if we determine that it is no longer necessary to store personal information due to lack of use for a certain period of time, we will store the customer's personal information in order to prevent leakage of personal information. We may erase your personal information.")}</p>
              </Panel>
              <Panel header={t('The meeting place')} key="7">
                <p>{t('We will pick you up from the following designated hotels.')}
                  <br />
                  {t('Designated Hotel: Daewoo Hotel')}（Số 360 đường Kim Mã, quận Ba Đình, Hà Nội）、
                  <br />
                  {t('Hilton Hanoi Hotel')}（ số 1 Lê Thánh Tông, Phan Chu Trinh, Hoàn Kiếm, Hà Nội） 、
                  <br />
                  {t('Pan Pacific Hotel')}（Số 1 đường Thanh Niên, quận Ba Đình, Hà Nội）{t('it will be one of the above hotels.')}
                  <br />
                  {t('If you would like a hotel pick-up other than the above, please contact us at the time of application.')}
                  <br />
                  {t('In addition, in the case of meeting other than the hotel, it will be the Opera House meeting.')}
                  <br />
                  {t('Opera House Address')}：Số 01 Tràng Tiền – Q.Hoàn Kiếm – Hà Nội – Việt Nam.</p>
              </Panel>
              <Panel header={t('Car arrangement')} key="8">
                <p>{t('We will arrange the car after confirming the number of customers on the day.')}
                  <br />
                  {t('With comfortable seats, we offer a journey that will not make you feel tired.')}
                  <br />
                  {t('1-2 passengers: 4-seat car')}
                  <br />
                  {t('Up to 3-4 passengers: car with 7 seats')}
                  <br />
                  {t('5-9 passengers: car with 16 seats')}
                  <br />
                  {t('10 or more passengers: 29-seat car')}</p>
              </Panel>
              <Panel header={t('Notes')} key="9">
                <p>1.{t(' The tour may be canceled due to weather conditions, unforeseen disasters, or other force majeure reasons.')}
                  <br />
                  2.{t(' Please keep your luggage at your own risk. We are not responsible for any damage, loss or theft. Please be careful with your belongings.')}
                  <br />
                  3.{t(" Tours are subject to Vietnam's travel agency law. Therefore, the terms and conditions based on the Travel Agency Law of Japan do not apply. In addition, if the customer violates Vietnamese law and public order and morals, or if the customer does not meet at the meeting time, we may refuse to participate in the tour or continue.")}
                  <br />
                  4.{t(' The order of visits may change.')}
                  <br />
                  5.{t(' Please wear comfortable flat-soled shoes.')}
                  <br />
                  6.{t(' The sun is scorching in summer, so please prepare sunshade measures and cold weather gear in winter.')}
                  <br />
                  7.{t(' The product will be operated by 2 people or more. Please contact us if you are using one person.')}</p>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </main>
  );
};

export default RulesContainer;