import React from 'react'
import { useTranslation } from 'react-i18next'

import SelectInput from '../SelectInput/SelectInput'
import i18n from '../../i18n'
import './language.scss'

const language = [
  {
    value: 'jp',
    text: 'JP',
  },
  {
    value: 'us',
    text: 'EN',
  },
  {
    value: 'vn',
    text: 'VN',
  },
]
function Language() {
  const { t } = useTranslation()
  const languageLocal = window.localStorage.getItem('LANGUAGE') ? window.localStorage.getItem('LANGUAGE') : null;
  
  return (
    <div className='language'>
      <SelectInput
        flag={true}
        showSearch={false}
        onChange={(value) => {
          i18n.changeLanguage(value)
          window.localStorage.setItem('LANGUAGE', value);
        }}
        defaultValue={languageLocal ? languageLocal :language[0]}
        items={language}
      />
    </div>
  )
}

export default Language
