import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Button from '../../components/Button/Button'
import CardDetail from '../../components/Card/CardDetail'
import Checkbox from '../../components/Checkbox/Checkbox'
import FilledInfo from '../../components/Filled/FilledInfo'
import FilledLocation from '../../components/Filled/FilledLocation'
import { setInformation, setStatusStep } from '../FilledContainer/action'
import { ROUTES } from '../../constant/ROUTES'
import { OtherService } from '../../data'
import { getDetailCar, booking } from '../../apis/carApi'
import { formatMoney } from '../../helpers/common'
import { Steps } from 'antd'

import './confirm.scss'
import StepBooking from '../../components/Steps/Steps'

const Confirm = ({ bookingData, setInformation, step, setStatus }) => {
  const [car, setCar] = useState({})
  const [total, setTotal] = useState()
  const navigate = useNavigate()
  const location = useLocation()
  console.log('bookingData', bookingData)
  const fetchCarInfo = async (id) => {
    const res = await getDetailCar(id)
    setCar(res.data[0])
    setInformation('total_price', res.data[0].price)
  }
  const saveBooking = (data) => {
    booking({ ...data, pay: 'Credit' }).then((response) => {
      window.location.href = response.data.link
      // window.open(response.data.link)
    })
    // setStatus('confirm', 'finish')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (bookingData.carId) {
      fetchCarInfo(bookingData.carId)
    }
    setStatus('confirm', 'process')
  }, [])
  useEffect(() => {
    if (total) {
      setInformation('total_price', total.toString())
    }
  }, [total])

  useEffect(() => {
    if (!bookingData.carId) {
      navigate('/cars')
    }
  }, [location])

  useEffect(() => {
    if (car.price) {
      setTotal(Number(car.price) * Math.ceil((new Date(bookingData.endDate).getTime() - new Date(bookingData.startDate).getTime()) / (1000 * 60 * 60 * 24)))
    }
  }, [car.price])

  const { t } = useTranslation()

  return (
    <main>
      <div className='confirm'>
        <div className='container'>
          <StepBooking
            current={1}
            status={step} />
          <h1 className='confirm_heading text_heading'>
            Confirm booking information
          </h1>
          {booking && (
            <div className='confirm_container'>
              <div className='confirm_car'>
                <CardDetail
                  key={car.id}
                  name={car.name}
                  image={car.imagePath}
                  price={car.price}
                  seats={car.seats}
                  transmission={car.transmission}
                  brand={car.brand}
                />
              </div>
              <div className='confirm_form'>
                <FilledInfo
                  endDate={bookingData && bookingData.endDate}
                  startDate={bookingData && bookingData.startDate}
                  booking={bookingData && bookingData}
                  disabled={true}
                />
              </div>
              <div className='confirm_service'>
                <h3 className='confirm_service-heading text_l'>
                  {t('Other services')}
                </h3>
                <div className='confirm_service-group'>
                  {OtherService.map((item, index) => (
                    <Checkbox
                      label={item.name}
                      key={bookingData && `${bookingData[item.key]}${index}`}
                      id={bookingData && bookingData[item.key]}
                      value={bookingData && bookingData[item.key]}
                      checked={bookingData && bookingData.otherService[item.key]}
                      disabled={true}
                    />
                  ))}
                </div>
              </div>
              <div className='confirm_pay'>
                <h3 className='confirm_pay-heading text_l'>{t('Payment methods - Credit Card')}</h3>
              </div>
              <div className='confirm_total'>
                <h2 className='text_heading'>
                  {t('Total Cost:')}
                  <span> {formatMoney({ value: total, locales: 'vi-VN' },)} VND</span>
                </h2>
              </div>
              <div className='confirm_button'>
                <Link to={ROUTES.FILLED}>
                  <Button text='Edit' otherClass={'button_primary gradient_red'} />
                </Link>

                <Button
                  text='CONFIRM'
                  otherClass='button_primary gradient_green'
                  onClick={() => saveBooking(bookingData)}
                // onClick={() =>
                //   window.open(
                //     'https://sandbox.vnpayment.vn/paymentv2/vpcpay.html?vnp_Amount=1806000&vnp_Command=pay&vnp_CreateDate=20210801153333&vnp_CurrCode=VND&vnp_IpAddr=127.0.0.1&vnp_Locale=vn&vnp_OrderInfo=Thanh+toan+don+hang+%3A5&vnp_OrderType=other&vnp_ReturnUrl=https%3A%2F%2Fdomainmerchant.vn%2FReturnUrl&vnp_TmnCode=DEMOV210&vnp_TxnRef=5&vnp_Version=2.1.0&vnp_SecureHash=3e0d61a0c0534b2e36680b3f7277743e8784cc4e1d68fa7d276e79c23be7d6318d338b477910a27992f5057bb1582bd44bd82ae8009ffaf6d141219218625c42'
                //   )
                // }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  )
}

export function mapStateToProps(state) {
  return {
    bookingData: state.booking.data,
    step: state.booking.stepStatus.confirm,
  }
}
export function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setInformation,
    setStatus: setStatusStep,
  }, dispatch)
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(Confirm)
