import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next'
import './button.scss'
const Button = ({ text, onClick, otherClass, disabled, children }) => {
  const { t } = useTranslation()
  return (
    <button className={`${otherClass} ${disabled}`} onClick={onClick}>
      {t(text)}
      {children}
    </button>
  )
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  otherClass: PropTypes.string,
  disabled: PropTypes.string
};

Button.defaultProps = {
  text: "",
  onClick: () => { },
  otherClass: '',

};

export default Button;
