export const formatMoney = ({ value, locales }) => {
  //   const money = new Intl.NumberFormat(locales, {
  //     style: 'currency',
  //     currency: unit,
  //   }).format(value)
  if (locales === 'US') {
    const money = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value)
    return money
  } else {
    const money = new Intl.NumberFormat().format(value).replace(/,/g, '.')
    return money
  }
}
