import { Link } from 'react-router-dom'
import { motion, } from "framer-motion";

import { ROUTES } from '../../constant/ROUTES'
import './cardLux.scss'
import { useTranslation } from 'react-i18next'
const CardLux = ({
  name,
  image,
  onClick
}) => {
  const { t } = useTranslation()

  return (
    <motion.div className="cardLux"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={onClick}
      transition={{ type: "spring", stiffness: 300, damping: 20 }}  >
      <div className="cardLux_image">
        <img src={image} alt={name} />
      </div>
      <p className='cardLux_name text_l'>{name}</p>
      <button onClick={onClick} className='cardLux_book button_primary gradient_red text_m'>
        {t('Contact Now')}
      </button>
    </motion.div>
  )
}

export default CardLux
