import { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { faCar, faCouch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { motion, } from "framer-motion";

import { formatMoney } from '../../helpers/common'
import { ROUTES } from '../../constant/ROUTES'

import './card.scss'
const CardItem = ({
  image,
  price,
  seats,
  transmission,
  containerStyle,
  brand,
  onClick,
  visible
}) => {



  const { t } = useTranslation()

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }}
      className='card_item'
      onClick={onClick} >
      <div className='card_image'>
        <img src={image} alt={brand} />
      </div>
      <div className='card_info'>
        <p className='card_name text_m'>{brand}</p>
        <p className='card_price text_m'>
          {t('{{ price }} VND / Day', {
            price: formatMoney({ value: price, locales: 'vi-VN' }),
          })}
        </p>
        <p>For 9 hours/100km</p>
      </div>
      <div className='card_desc' style={{ ...containerStyle }}>
        <div className='card_desc-item'>
          <FontAwesomeIcon icon={faCouch} />
          <p className='card_desc-item-text text_s'>
            {t('{{ seat }} Seats', { seat: seats })}
          </p>
        </div>
        <div className='card_desc-item'>
          <FontAwesomeIcon icon={faCar} />
          <p className='card_desc-item-text text_s'>{t(transmission)}</p>
        </div>
      </div>
      <button className='card_book button_primary gradient_red text_m' style={{ display: `${visible}` }}>
        {t('Booking')}
      </button>
    </motion.div>
  )
}

CardItem.propTypes = {
  containerStyle: PropTypes.object,
}

CardItem.defaultProps = {
  containerStyle: {
    borderTop: '1px solid #eeeeee',
  },
}
export default CardItem
