/* eslint-disable react-hooks/exhaustive-deps */
import { Select } from 'antd'
import { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid, Pagination } from 'swiper'
import Modal from 'react-modal';
import { ROUTES } from '../../constant/ROUTES'
import CardItem from '../../components/Card/Card'
import SelectDate from '../../components/SelectDate/SelectDate'
import ButtonFilter from '../../components/Button/ButtonFilter'
import * as actions from '../HomeContainer/action'
import * as action from './action'
import { setInformation, setCarBooking } from '../FilledContainer/action'
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/pagination'
import "swiper/css/navigation";
import './cars.scss'
import '../../styles/index.scss'
import { useState } from 'react'
import CardLux from '../../components/Card/CardLux'
import ContentModal from '../../components/ContentModal/ContentModal'
import AlertModal from '../../components/ContentModal/AlertModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../components/Button/Button'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../../components/Spinner/Spinner'
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    borderRadius: '20px',
    maxWidth: '80%',

  },
};
const { Option } = Select
const CarsContainer = ({
  startDate,
  endDate,
  setLocation,
  setStartDate,
  setEndDate,
  filter,
  getBrand,
  setSeatsQuantityToSearch,
  setPriceToSearch,
  setBrandToSearch,
  setTransmissionToSearch,
  getSeats,
  seats,
  cars,
  brand,
  setProvinces,
  listProvince,
  province,
  fetchProvinces,
  search,
  setInfo,
  setCarID,
  resetSeats,
  fetchingSearch

}) => {
  useEffect(() => {
    getSeats()
    // filter()
    if (search.startDate && search.endDate && province) {
      setInfo('startDate', search.startDate)
      setInfo('endDate', search.endDate)
      filter()
    }
    getBrand()
    fetchProvinces()
  }, [])
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [visible, setVisible] = useState(false)
  const [visibleDate, setVisibleDate] = useState(false)
  const today = moment().format('YYYY-MM-DD')
  const [checkDate, setCheckDate] = useState(false)

  // useEffect(() => {
  //   const list = cars && cars.filter(car => car.provinceId === province)
  //   setListFilter(list)
  // }, [province, setListFilter])
  // console.log('listFilter', listFilter)

  useEffect(() => {
    // if (search.startDate && search.endDate && province) {
    //   setInfo('startDate', search.startDate)
    //   setInfo('endDate', search.endDate)
    //   filter()
    // }

    if (search.startDate > search.endDate) {
      setEndDate(moment(search.startDate).add(7, 'd').format('YYYY-MM-DD HH:mm:ss'),)
      setInfo('endDate', moment(search.startDate).add(7, 'd').format('YYYY-MM-DD HH:mm:ss'),)

    }



    if (search.startDate === search.endDate) {
      setCheckDate(true)
      setVisibleDate(true)
    }
    return () => {
      setCheckDate(false)
    }
  }, [search])
  // useEffect(() => {
  //   if (location.state) {
  //     setSeatsQuantityToSearch(location.state.seats)
  //   }
  // }, [location.key])

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }, [location])


  const closeModal = () => {
    setVisible(false);
  }

  const closeModalDate = () => {
    setCheckDate(false)
    setVisibleDate(false)
  }


  const handleClickCars = (id) => {
    if (checkDate) {
      setVisibleDate(true)
      return
    }
    setCarID(id)
    navigate(ROUTES.FILLED)
    resetSeats()
  }
  return (
    <main className='cars'>
      <div className='search'>
        <div className='container'>
          <div className='search_container'>
            <div className='search_item search_location'>
              {/* <SelectInput
                onChange={(value) => setLocation(value.target.value)}
                name={'Location'}
                otherClass={'input_location'}
                placeholder={'Location'}
                defaultValue={location}
              /> */}
              <SelectDate
                disabledDate={(current) =>
                  current && current <= moment(today)
                }
                onOk={(value) => {
                  setInfo(
                    'startDate',
                    value.format('YYYY-MM-DD HH:mm:ss')
                  )

                  setStartDate(value.format('YYYY-MM-DD HH:mm:ss'))
                }
                }
                defaultValue={startDate ? moment(startDate) : null}
                label={'Pick-up Date'}
              />
              <SelectDate
                disabledDate={(current) =>
                  current && current < moment(startDate)
                }
                onOk={(value) => {
                  setInfo(
                    'endDate',
                    value.format('YYYY-MM-DD HH:mm:ss')
                  )
                  setEndDate(value.format('YYYY-MM-DD HH:mm:ss'))

                }
                }
                defaultValue={endDate ? moment(endDate) : null}
                label={'Drop-off Date'}
              />

              <div className="search_item-group">
                <p className='text_m'>{t('Place To Rent')} <span>*</span></p>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  value={province}
                  optionFilterProp="children"
                  onChange={(value) => setProvinces(value)}
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {
                    listProvince.length > 0 &&
                    listProvince.map(option =>
                      <Option
                        key={option.name + option.id.toString()}
                        value={option.id}>{option.name}
                      </Option>)
                  }
                </Select>
              </div>
            </div>
            <div className='search_item'>
              <div className="search_item-group">
                <p className='text_m'>{t('Seating Capacity')}</p>
                <div className='search_filter'>
                  {seats.map((item) => (
                    <ButtonFilter
                      key={item.seats}
                      seats={item.seats}
                      onClick={setSeatsQuantityToSearch}
                      selected={search.seats}
                    />
                  ))}
                </div>
              </div>
              {/* <div className="search_item-group">
                <p className='text_m'>{t('Filter Option')}</p>
                <InputNumber
                  style={{ width: 200, borderRadius: 10 }}
                  placeholder='PRICE'
                  // defaultValue={1000}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  addonAfter={<p>VND</p>}
                  onChange={(value) => setPriceToSearch(value)}
                  disabled={search.startDate && search.endDate ? false : true}
                />
              </div> */}
              <Button
                otherClass={'button_primary search_button'}
                onClick={() => filter()}
                disabled={search.startDate && search.endDate && province ? '' : 'disabled'}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Button>
            </div>
            {/* <div className='search_item'>
              Filter Brand
              <Select
                  mode='multiple'
                  maxTagCount='responsive'
                  placeholder={t('Brand')}
                  style={{ width: 150, marginLeft: 20 }}
                  onChange={(value) => setBrandToSearch(value)}
                >
                  <Option value='All'>{t('All')}</Option>
                  {brand.map((item) => (
                    <Option key={item.name} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>

              Filter Che do lai xe tu dong/so san
              <Select
                  maxTagCount='responsive'
                  placeholder={t('Transmission')}
                  // defaultValue={t('Transmission')}
                  style={{ width: 150, marginLeft: 20 }}
                  onChange={(value) => setTransmissionToSearch(value)}
                >
                  <Option value='All'>{t('All')}</Option>
                  <Option value='Manual'>{t('Manual')}</Option>
                  <Option value='Auto'>{t('Auto')}</Option>
                </Select>
            </div> */}
          </div>
        </div>
      </div>
      <div className='category'>
        <div className='container'>
          <Swiper
            // navigation={true}
            slidesPerView={4}
            // grid={{ rows: cars && cars.length >= 8 ? 2 : 1, }}
            grid={{ rows: 1 }}
            pagination={{
              clickable: true,
            }}
            // modules={[Grid, Pagination, Navigation]}
            modules={[Grid, Pagination]}
            className="mySwiper"
          >
            {
              cars && cars.length > 0 ? cars.map((item) => (
                <SwiperSlide>
                  <CardItem
                    key={item.id}
                    id={item.id}
                    image={item.imagePath || '/image/car_null.png'}
                    price={item.price}
                    seats={item.seats}
                    brand={item.brand}
                    transmission={item.transmission}
                    onClick={() => handleClickCars(item.id)}
                  />
                </SwiperSlide>
              ))
                :
                <div className='category_none'>
                  <h3 className='text_heading'>{t('No matching cars found.')}</h3>
                  <p className='text_m'>{t('Try removing some filters to see more results.')}</p>
                </div>
            }
          </Swiper>
        </div>
      </div>

      <section className='featured'>
        <h1 className='featured_heading text_heading'>{t('Luxury Car')}</h1>
        <div className="featured_list">
          <CardLux
            image={'./image/mec_c63.png'}
            name='Mercedes'
            onClick={() => setVisible(true)}
          />
          <CardLux
            onClick={() => setVisible(true)}
            image={'./image/Dcar.png'}
            name='D-Car Limousine'
          />
          <Modal
            isOpen={visible}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <ContentModal
              title={t('Booking Luxury Car')}
              content={t('Contact us by the form below if you have a need to use special high-end vehicles.')}
              onClose={() => setVisible(false)} />
          </Modal>
          <Modal
            isOpen={visibleDate}
            onRequestClose={closeModalDate}
            style={customStyles}
          >
            <AlertModal onClose={() => setVisibleDate(false)}
              content={t('Drop-off must be after pick-up, please change the dates and/or times for your search')}
            />
          </Modal>
        </div>
        {/* <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={3}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2,
            slideShadows: false,
          }}
          loop={true}
          autoplay={{ delay: 3500 }}
          modules={[EffectCoverflow, Autoplay, Virtual]}
          className='mySwiper'
        >
          {cars.map((item, index) => (
            <SwiperSlide>
              {({ isActive }) => (
                <CardFeatured
                  isActive={isActive}
                  id={item.id}
                  key={item.licensePlates}
                  image={item.image || './image/hondacivic.png'}
                  name={item.name}
                  price={item.price}
                  seat={item.seat}
                  transmission={item.transmission}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper> */}
        {fetchingSearch && <Spinner />}
      </section>

    </main>
  )
}

export function mapStateToProps(state) {
  return {
    cars: state.listCar.data,
    fetching: state.listCar.fetching,
    location: state.search.location,
    startDate: state.search.startDate,
    endDate: state.search.endDate,
    brand: state.brand.data,
    seats: state.listCar.seats,
    seatsSelected: state.search.seats,
    listProvince: state.listProvince.data,
    province: state.search.provinces,
    search: state.search,
    fetchingSearch: state.search.fetching

  }
}
export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...actions,
      ...action,
      setInfo: setInformation,
      setCarID: setCarBooking
    },
    dispatch
  )
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CarsContainer
)
