import { combineReducers } from 'redux'
import { DETAIL_CAR_REDUCER } from '../../constant/constant'

const initialValue = {
  fetching: false,
  data: [],
  sameCars: [],
  error: '',
}

export const detailCarReducer = (state = initialValue, action) => {
  switch (action.type) {
    case DETAIL_CAR_REDUCER.SET_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case DETAIL_CAR_REDUCER.SET_SAME_CARS:
      return {
        ...state,
        sameCars: action.payload,
      }
    case DETAIL_CAR_REDUCER.FETCHING:
      return {
        ...state,
        fetching: true,
      }
    case DETAIL_CAR_REDUCER.FETCH_DONE:
      return {
        ...state,
        fetching: false,
      }
    case DETAIL_CAR_REDUCER.ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default combineReducers({
  detailCar: detailCarReducer,
})
