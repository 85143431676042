import { Button } from 'antd'
import React from 'react'
import './ContentModal.scss'

const ContentModal = ({ onClose, notPaid, paymentAgain, cancelPayment, title, content }) => {
  return (
    <div className="modal-container">
      <div className="modal">
        <h1 className="modal__title">{title}</h1>
        <p className="modal__text">{content}</p>
        <div className="modal__group">
          <button className="button_primary gradient_red text_l" style={{ marginRight: 20 }}>
            {!notPaid ? (<a href="tel: 0968731699">Tel: +8496-873-1699</a>) : (<a onClick={cancelPayment}>
              Cancel Payment</a>)}
          </button>
          <button className="button_primary gradient_green text_l">
            {
              !notPaid ? (<a href="mailto: eigyou@aitravelgroup.com">Email: eigyou@aitravelgroup.com</a>) : (
                <a onClick={paymentAgain}>Payment Again</a>
              )
            }
          </button>
        </div>
        <button className="modal__close" onClick={onClose}></button>
      </div>
    </div>
  )
}

export default ContentModal