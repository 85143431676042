/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Virtual, EffectCoverflow, Autoplay } from 'swiper'
import { Select } from 'antd'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import {
  faClock,
  faCoins,
  faStarHalfStroke,
} from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AOS from 'aos'
import { motion } from "framer-motion";
import moment from 'moment'
import Modal from 'react-modal';
import { BrandCarData, Introduce } from '../../data'
import SelectDate from '../../components/SelectDate/SelectDate'
import './home.scss'
import '../../styles/index.scss'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import 'aos/dist/aos.css' //
import CardItem from '../../components/Card/Card'
import Button from '../../components/Button/Button'
import { ROUTES } from '../../constant/ROUTES'
import * as action from './action'
import CardLux from '../../components/Card/CardLux'
import { useState } from 'react'
import ContentModal from '../../components/ContentModal/ContentModal'
import CardIntro from '../../components/Card/CardIntro'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    borderRadius: '20px',
    maxWidth: '80%',
  },
};

const HomeContainer = ({
  cars,
  fetch,
  setEndDate,
  setStartDate,
  search,
  filter,
  setSeatsQuantityToSearch,
  setBrandToSearch,
  booking,
  error,
  touched,
  setInformation,
  setProvinces,
  fetchProvinces,
  listProvince,
  resetFilter,
  getSeats,
  seats,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)

    getSeats()
    fetchProvinces()
    // window.scrollTo(0, 0)
  }, [])
  // useEffect(() => {
  //   console.log('search', search)
  //   filter()
  // }, [search.brand, search.seats])
  // window.scrollTo(0, 0)
  useEffect(() => {
    if (search.startDate > search.endDate) {
      setEndDate(moment(search.startDate).add(7, 'd').format('YYYY-MM-DD HH:mm:ss'),)
    }
  }, [search])

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { Option } = Select
  const location = useLocation()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }, [location])


  AOS.init({
    duration: 1500,
    once: true,
    delay: 200,
  })

  const msList = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.3
      }
    }
  }

  const msItem = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };
  const closeModal = () => {
    setVisible(false);
  }
  return (
    <main>
      <section className='hero'>
        <div className='container'>
          <div className='hero_container'>
            <div className='hero_content' data-aos='fade-right'>
              <h3 className='hero_content-heading'>
                {t('Rental Cars with Drivers')}
              </h3>
              <div className='hero_title'>
                <p className='hero_title-item text_l'>
                  {t('Supports Japanese')}
                </p>
                <p className='hero_title-item text_l'>
                  {t('Online Payment')}
                </p>
                <p className='hero_title-item text_l'>
                  {t('Luxury Cars Available')}
                </p>
              </div>
            </div>
            <div className='hero_banner' data-aos='fade-up'>
              <div
                className='hero_image'
                data-aos='fade-left'
                data-aos-delay='800'
              >
                <img src='./image/hero_image.png' alt='bookvipcar' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className='hero_search-container'>
        <div className="container">
          <section className='hero_search' data-aos='fade-up'>
            <SelectDate
              touched={touched}
              error={error}
              disabledDate={(current) =>
                current && current < moment(search.startDate)
              }
              defaultValue={
                search.startDate ? moment(search.startDate) : null
              }
              label='Pick-up Date'
              name='startDate'
              onOk={(value) => {
                setInformation(
                  'startDate',
                  value.format('YYYY-MM-DD HH:mm:ss')
                )
                setStartDate(value.format('YYYY-MM-DD HH:mm:ss'))
              }}
            />
            <SelectDate
              touched={touched}
              error={error}
              disabledDate={(current) =>
                current && current < moment(search.startDate)
              }
              defaultValue={
                search.endDate ? moment(search.endDate) : null
              }
              label='Drop-off Date'
              name='endDate'
              onOk={(value) => {
                setInformation(
                  'endDate',
                  value.format('YYYY-MM-DD HH:mm:ss')
                )
                setEndDate(value.format('YYYY-MM-DD HH:mm:ss'))
              }}
            />
            <div className='hero_search-provinces'>
              <label className='text_m'>{t('Place To Rent')}<span>*</span></label>
              <Select
                showSearch
                style={{
                  width: 200,
                }}
                placeholder="Search to Select"
                value={search.provinces}
                optionFilterProp="children"
                onChange={(value) => setProvinces(value)}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
              >
                {
                  listProvince.length > 0 && listProvince.map(option => <Option key={option.name + option.id.toString()} value={option.id}>{option.name}</Option>)
                }
              </Select>
            </div>
            <Button
              otherClass={'hero_search-button button_primary gradient_blue'}
              onClick={() => navigate(`${ROUTES.CARS}`)}
              text={t('Search')}
              style={{ paddingLeft: 10 }}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Button>
          </section>
        </div>
      </div>

      <section className='category'>
        <div className='container'>
          <div className='category_container' data-aos="fade-up">
            <h2 className='category_heading text_heading'>
              {t('Where Are You Going?')}
            </h2>
            <div className="category_grid">
              {Introduce.map((item) =>
                <CardIntro
                  key={item.label}
                  image={item.image}
                  label={item.label}
                  onClick={() => navigate(ROUTES.CARS)}
                />)
              }
            </div>

            {/* <div className='category_filter'>
              <p className='category_filter-heading text_l'>{t('Book now')}</p>
              <div className='category_filter-list'>
                <div className='category_filter-item'>
                  <p className='category_filter-item-heading text_m'>
                    {t('Seats')}
                  </p>
                  <Select
                    maxTagCount='responsive'
                    mode='multiple'
                    placeholder='Please select'
                    allowClear
                    style={{ width: 150 }}
                    onChange={(value) => setSeatsQuantityToSearch(value)}
                  >
                    {seats.map((item) => (
                      <Option key={item.id} value={item.seats}>
                        {t('{{ seat }} Seats', { seat: item.seats })}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div> */}
            {/* <div className='category_gallery'>
              {cars.map((item) => (
                <CardItem
                  key={item.licensePlates}
                  id={item.id}
                  image={item.image || './image/hondacivic.png'}
                  brand={item.brand}
                  price={item.price}
                  seats={item.seats}
                  transmission={item.transmission}
                  onClick={() => navigate(ROUTES.FILLED)}
                  visible='none'
                />
              ))}
            </div> */}
            {/* <Link to={ROUTES.CARS}>
              <Button
                otherClass={'category_button button_primary'}
                text={t('More car')}
              />
            </Link> */}
          </div>
        </div>
      </section>

      <section className='featured' data-aos="fade-up">
        <h1 className='featured_heading text_heading'>{t('Luxury Car')}</h1>
        <div className="featured_list">
          <CardLux
            image={'./image/mec_c63.png'}
            name='Mercedes'
            onClick={() => setVisible(true)}
          />
          <CardLux
            onClick={() => setVisible(true)}
            image={'./image/Dcar.png'}
            name='D-Car Limousine'
          />
          <Modal
            isOpen={visible}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <ContentModal onClose={() => setVisible(false)}
              title={t('Booking Luxury Car')}
              content={t('Contact us by the form below if you have a need to use special high-end vehicles.')}
            />
          </Modal>
        </div>
        {/* <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={3}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2,
            slideShadows: false,
          }}
          loop={true}
          autoplay={{ delay: 3500 }}
          modules={[EffectCoverflow, Autoplay, Virtual]}
          className='mySwiper'
        >
          {cars.map((item, index) => (
            <SwiperSlide>
              {({ isActive }) => (
                <CardFeatured
                  isActive={isActive}
                  id={item.id}
                  key={item.licensePlates}
                  image={item.image || './image/hondacivic.png'}
                  name={item.name}
                  price={item.price}
                  seat={item.seat}
                  transmission={item.transmission}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper> */}
      </section>

      <section className='brand' data-aos="fade-up">
        <div className='container'>
          <motion.div className='brand_container'
            variants={msList}
            initial="hidden"
            animate="visible"
          >
            {BrandCarData.map((item, i) => (
              <motion.div key={item.id}
                className='brand_item'
                variants={msItem}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}   >
                <img src={item.image} alt={item.name} />
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>

      <section className='strength'>
        <div className='container'>
          <div className='strength_container'>
            <div className='strength_content' data-aos="fade-right">
              <h1 className='strength_content-heading text_heading'>
                {t('Get the best experience with your rental deals')}
              </h1>
              <div className='strength_content-list'>
                <div className='strength_content-item'>
                  <FontAwesomeIcon icon={faCoins} />
                  <p className='text_m'>{t('Best price guaranteed')}</p>
                </div>
                <div className='strength_content-item'>
                  <FontAwesomeIcon icon={faClock} />
                  <p className='text_m'>{t('Support 24/7')}</p>
                </div>
                <div className='strength_content-item'>
                  <FontAwesomeIcon icon={faStarHalfStroke} />
                  <p className='text_m'>{t('Pristine & Comfortable')}</p>
                </div>
              </div>
            </div>
            <div className='strength_image' data-aos="fade-left">
              <img src='./image/hondacivic.png' alt='bookvipcar' />
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export function mapStateToProps(state) {
  return {
    // cars: state.listCar.data,
    booking: state.booking.data,
    fetching: state.listCar.fetching,
    error: state.booking.error,
    touched: state.booking.touched,
    search: state.search,
    listProvince: state.listProvince.data,
    seats: state.listCar.seats,
  }
}
export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...action,
    },
    dispatch

  )
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  HomeContainer
)
