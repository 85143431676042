import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import './textarea.scss'

const Textarea = ({
  label,
  otherClass,
  onChange,
  id,
  value,
  name,
  type,
  placeholder,
  style,
  disabled,
}) => {
  const { t } = useTranslation()
  return (
    <div className='textareaItem' style={style}>
      <label className='text_m'>{t(label)}</label>
      <textarea
        className={`${otherClass}`}
        type={type}
        onChange={onChange}
        id={id}
        value={value}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  )
}

Textarea.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

Textarea.defaultProps = {
  type: 'text',
  label: 'abc',
  onchange: () => {},
}

export default Textarea
