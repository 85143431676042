/** @format */

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'

import './i18n'
import './App.scss'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import HomeContainer from './container/HomeContainer/Home'
import CarsContainer from './container/CarsContainer/Cars'
import CarContainer from './container/CarContainer/Car'
import FilledContainer from './container/FilledContainer/Filled'
import ConfirmContainer from './container/ConfirmContainer/Confirm'
import SuccessContainer from './container/SuccessContainer/Success'
import RulesContainer from './container/RulesContainer/Rules'
import NotFoundContainer from './container/NotFound/Container'
import { ROUTES } from './constant/ROUTES'
import configureStore from './store/configStore'

const history = createBrowserHistory()
const store = configureStore(history)

function App() {
  return (
    <Router>
      <Header />
      <div className='wrapper'>
        <Routes>
          <Route exact path={ROUTES.HOME} element={<HomeContainer />} />
          <Route exact path={ROUTES.CARS} element={<CarsContainer />} />
          <Route exact path={ROUTES.CAR} element={<CarContainer />} />
          <Route exact path={ROUTES.FILLED} element={<FilledContainer />} />
          <Route exact path={ROUTES.CONFIRM} element={<ConfirmContainer />} />
          <Route exact path={ROUTES.SUCCESS} element={<SuccessContainer />} />
          <Route exact path={ROUTES.RULES} element={<RulesContainer />} />
          <Route path='*' element={<NotFoundContainer />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  )
}

const ConnectedApp = () => (
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>
)

export default ConnectedApp
