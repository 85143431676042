import { DETAIL_CAR_REDUCER, UNKNOWN_ERROR,BOOKING_INFORMATION_REDUCER } from '../../constant/constant'

export const fetch = (id) => async (dispatch, getState, thunkDependencies) => {
  try {
    dispatch({
      type: DETAIL_CAR_REDUCER.FETCHING,
    })

    const [detailCar, sameCars] = await Promise.all([
      thunkDependencies.car.getDetailCar(id),
      thunkDependencies.car.getSameCars(id),
    ])
    dispatch({
      type: DETAIL_CAR_REDUCER.SET_DATA,
      payload: detailCar.data[0],
    })
    dispatch({
      type: DETAIL_CAR_REDUCER.SET_SAME_CARS,
      payload: sameCars.data,
    })
    dispatch({
      type: DETAIL_CAR_REDUCER.FETCH_DONE,
    })
  } catch (error) {
    const message = error.message || UNKNOWN_ERROR
    dispatch({
      type: DETAIL_CAR_REDUCER.ERROR,
      payload: message,
    })
  }
}


