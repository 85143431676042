import { BRAND_REDUCER } from '../constant/constant'

const initialValue = {
  data: [],
}

export const brandReducer = (state = initialValue, action) => {
  switch (action.type) {
    case BRAND_REDUCER.SET_BRAND:
      return {
        ...state,
        data: action.payload,
      }

    default:
      return state
  }
}
