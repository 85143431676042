import PropTypes from 'prop-types'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { hasFlag } from 'country-flag-icons'
import { US, JP, VN } from 'country-flag-icons/react/3x2'

import './SelectInput.scss'
const SelectInput = ({
  onChange,
  placeholder,
  otherClass,
  name,
  defaultValue,
  items,
  showSearch,
  flag,
}) => {
  const { t } = useTranslation()
  return (
    // <input className={`${otherClass}`}  placeholder={placeholder} onChange={onChange} />
    <div className='search_location-item'>
      <p className='text_m'>{t(name)}</p>
      <Select
        showSearch={showSearch}
        showArrow={false}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
      >
        {items.map((item) => (
          <Select.Option key={item.value} value={item.value}>
            {flag && item.text === 'EN' && (
              <US className='flag' title={t(item.text)} />
            )}
            {flag && item.text === 'VN' && (
              <VN className='flag' title={t(item.text)} />
            )}
            {flag && item.text === 'JP' && (
              <JP className='flag' title={t(item.text)} />
            )}
            {t(item.text)}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

SelectInput.propTypes = {
  onClick: PropTypes.func.isRequired,
  items: PropTypes.array,
}

SelectInput.defaultProps = {
  onClick: () => { },
  items: [],
}

export default SelectInput
