import { useTranslation } from 'react-i18next'
import Menu from '../Menu/Menu'
import Button from '../Button/Button'
import Language from '../Lang/Language'
import './header.scss'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '../../constant/ROUTES'
import { MenuSlide } from '../Menu/MenuSlide'
const Header = () => {
  const { t } = useTranslation()
  
  return (
    <header className='header'>
      <div className='container'>
        <div className='header_container'>
          <MenuSlide />
          <div className='header_logo'>
            <NavLink
              className='header_logo'
              to={ROUTES.HOME}
            >
              <img src='/image/logoHeader.png' alt='logo_bookvipcar'></img>
            </NavLink>
          </div>
          <Menu />
          <a className='header_hotline' href="tel: 0968731699">{t('Hotline: {{ phone }}', { phone: '096-873-1699' })}</a>
          <Language />
        </div>
      </div>
    </header>
  )
}

export default Header
