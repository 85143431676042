import { React, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Modal from 'react-modal';
import ContentModal from '../../components/ContentModal/ContentModal'
import { Col, Divider, Row } from 'antd';

import Button from '../../components/Button/Button'
import CardDetail from '../../components/Card/CardDetail'
import Checkbox from '../../components/Checkbox/Checkbox'
import FilledInfo from '../../components/Filled/FilledInfo'
import FilledLocation from '../../components/Filled/FilledLocation'
import { ROUTES } from '../../constant/ROUTES'
import { CarData, OtherService } from '../../data'
import { getDetailCar, updateBookingPaid } from '../../apis/carApi'
import * as actions from './action';
import './success.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"
import { formatMoney } from '../../helpers/common'
import { Steps } from 'antd'
import StepBooking from '../../components/Steps/Steps'
import { setStatusStep } from '../FilledContainer/action'
import { slice } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCouch } from '@fortawesome/free-solid-svg-icons'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    borderRadius: '20px',
    maxWidth: '80%',

  },
};
const Success = ({ fetch, infoSuccess, updateBookingPaid, payment, cancel, step, setStatus }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  console.log('infoSuccess', infoSuccess)

  const closeModal = () => {
    setVisible(false);
  }

  const paymentAgain = () => {
    if (search.bookId) {
      payment(search.bookId, infoSuccess.total_price).then(res => {
        // window.open(res.data.link)
        window.location.href = res.data.link
      })
    }
  }


  const cancelPayment = () => {
    if (search.bookId) {
      cancel(search.bookId)
      setVisible(false);
      navigate('/')
    }
  }


  const [car, setCar] = useState({})
  const [search, setSearch] = useState({
    bookId: null,
    transactionStatus: 0,
    responseCode: null,
    orderInfo: null,
  })


  const fetchCarInfo = async (id) => {
    try {
      const res = await getDetailCar(id)
      setCar(res.data[0])
    } catch (error) {
      console.log('asdasd')
      navigate('/notfound')
    }
   
  }
  useEffect(() => {
    if (infoSuccess.carId) {
        fetchCarInfo(infoSuccess.carId)
      
    }
    setStatus('success', 'finish')
  }, [infoSuccess])

  useEffect(() => {
    if (location.search === "") {
      navigate('/cars')
      return
    }
    const transactionStatus = location.search.substring(location.search.search('vnp_TransactionStatus'), location.search.search('vnp_TransactionStatus') + 24).slice(-2)
    const responseCode = location.search.substring(location.search.search('vnp_ResponseCode'), location.search.search('vnp_ResponseCode') + 19).slice(-2)
    const orderInfo = location.search.substring(location.search.search('vnp_OrderInfo'), location.search.search('vnp_OrderInfo') + 24).slice(-10)

    const bookId = location.pathname.substring(location.pathname.search('=') + 1)
    setSearch({
      bookId,
      transactionStatus,
      responseCode,
      orderInfo
    })

  }, [location.search])

  useEffect(() => {
    if (Number(search.responseCode) === 24) {
      setVisible(true)
    }
    if (search.bookId) {
      fetch(search.bookId)
    }
    if (search.bookId && !Number(search.transactionStatus)) {
      updateBookingPaid(search.bookId, search.transactionStatus, search.orderInfo)
    }
  }, [search])

  return (
    <main>
      {Number(search.transactionStatus) === 0 ?
        <div className='success'>
          <div className='container'>
            <StepBooking current={2} status={step} />
            <div className='success_message'>
              <FontAwesomeIcon icon={faCircleCheck} />
              <div className="success_message-content">
                <h1 className='success_heading text_l'>
                  {t('Thank you for your reservation.')} {t('Received.')}
                </h1>
                <p className='success_mess text_m'>
                  {t('Your reservation number is: ')}
                  {t('{{ bookId }}. ', { bookId: search.bookId })}
                  {t('Please tell us this number when you contact us.')}
                  <br />
                  {t('We have sent your reservation details to your email address.')}
                  <br />
                  {t('Please confirm.')}
                  <br />
                  {t('Our representative will contact you within 24 hours.')}
                </p>
              </div>
            </div>
            {car &&
              <div className='success_container'>
                <div className='success_car'>
                  {
                    <CardDetail
                      key={car.id}
                      image={car.imagePath}
                      price={car.price}
                      seats={car.seats}
                      transmission={car.transmission}
                      brand={car.brand}
                    />
                  }
                </div>
              </div>
            } 
            <h3 className='success_info-heading text_l'>{t('Booking summary')}</h3>

            <Row gutter={[20, 20]} className='success_info'>
              <Col span={24}>
                <h5 className='success_info-heading text_m'> {t('Your reservation number is: ')}
                  <span className='text_l'>{t('{{ bookId }} ', { bookId: search.bookId })}</span>
                </h5>
              </Col>
              <Col span={12}>
                <Row gutter={[45, 45]}>
                  <Col span={12} className='success_item'>
                    <p className='success_title text_s'>{t('Pick-up Date')}</p>
                    <p className="success_content text_m">{infoSuccess.startDate}</p>
                  </Col>
                  <Col span={12} className='success_item'>
                    <p className='success_title text_s'>{t('Drop-off Date')}</p>
                    <p className="success_content text_m">{infoSuccess.endDate}</p>
                  </Col>
                  <Col span={12} className='success_item'>
                    <p className='success_title text_s'>{t('Pick-up Place')}</p>
                    <p className="success_content text_m">{infoSuccess.startLocation}</p>
                  </Col>
                  <Col span={12} className='success_item'>
                    <p className='success_title text_s'>{t('Where To')}</p>
                    <p className="success_content text_m">{infoSuccess.endLocation}</p>
                  </Col>
                  <Col span={24} className='success_item'>
                    <p className='success_title text_s'>{t('Location Notes')}</p>
                    <p className="success_content text_m">{infoSuccess.noteLocation}</p>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row Row gutter={[45, 45]}>
                  <Col span={12} className='success_item'>
                    <p className='success_title text_s'>{t('First Name')}</p>
                    <p title={infoSuccess.firstName} className="success_content text_m">{infoSuccess.firstName}</p>
                  </Col>
                  <Col span={12} className='success_item'>
                    <p className='success_title text_s'>{t('Last Name')}</p>
                    <p className="success_content text_m">{infoSuccess.lastName}</p>
                  </Col>
                  <Col span={12} className='success_item'>
                    <p className='success_title text_s'>{t('Phone Number')}</p>
                    <p className="success_content text_m">{infoSuccess.phone}</p>
                  </Col>
                  <Col span={12} className='success_item'>
                    <p className='success_title text_s'>{t('Email')}ASDASDASD</p>
                    <p className="success_content text_m">{infoSuccess.email}</p>
                  </Col>
                  <Col span={24} className='success_item'>
                    <p className='success_title text_s'>{t('More Notes')}</p>
                    <p className="success_content text_m">{infoSuccess.other ? infoSuccess.other : '...'}</p>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <div className='success_service'>
                  <h3 className='success_service-heading text_l'>
                    {t('Other services')}
                  </h3>
                  <div className='success_service-group'>
                    {OtherService.map((Item) => (
                      <Checkbox
                        label={Item.name}
                        key={Item.id}
                        name={'otherService'}
                        id={Item.id}
                        // value={infoSuccess.otherService[Item.key]}
                        disabled={true}
                        checked={infoSuccess.otherService && infoSuccess.otherService[Item.key]}
                      />
                    ))}
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className='success_total'>
                  <h2 className='text_l'>
                    {t('Total Cost:')}
                  </h2>
                  <p className='text_l'> {formatMoney({ value: infoSuccess.total_price, locales: 'vi-VN' },)} VND</p>
                </div>
              </Col>
            </Row>


            <Link to={ROUTES.HOME}>
              <Button
                text={t('Home')}
                otherClass={'success_button button_primary gradient_green text_l'}
              />
            </Link>
          </div>
        </div>
        : <div className='successFail'>
          <div className="container">
            <StepBooking
              current={2}
              status={step}
              status2='error'
              status3='wait' />
            <div className="successFail_container">
              <div className="successFail_content">
                <h1 className='text_heading'>{t('Unpaid Transaction')}</h1>
                <p className='text_m'>{t('There is a problem with the payment operation. Would you like to pay again? If payment is not received within 60 minutes, the reservation will be cancelled.')}</p>
                <div className="successFail_button">
                  <Button
                    otherClass={'button_primary gradient_red'}
                    text={t('Cancel Payment')}
                    onClick={cancelPayment}
                  />
                  <Button
                    otherClass={'button_primary gradient_blue'}
                    text={t('Payment Again')}
                    onClick={paymentAgain}
                  />
                </div>
              </div>
              <div className="successFail_image">
                <img src="/image/payfail.png" alt="payfail" />
              </div>
            </div>
          </div>
        </div>
      }
      <Modal
        isOpen={visible}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <ContentModal
          title='Unpaid Transaction'
          content={'Payment failed. Do you want to pay again?'}
          cancelPayment={cancelPayment} paymentAgain={paymentAgain} notPaid onClose={() => setVisible(false)} />
      </Modal>
    </main>
  )
}

export function mapStateToProps(state) {
  return {
    infoSuccess: state.infoSuccess.data,
    step: state.booking.stepStatus.success,
  }
}
export function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...actions,
    setStatus: setStatusStep,
  }, dispatch)
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(Success)
