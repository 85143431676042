import {
  LIST_CAR_REDUCER,
  SEARCH_REDUCER,
  UNKNOWN_ERROR,
  FILTER_CAR_REDUCER,
  BOOKING_INFORMATION_REDUCER,
  PROVINCES_REDUCER
} from '../../constant/constant'

export const fetch = () => async (dispatch, getState, thunkDependencies) => {
  try {
    dispatch({
      type: LIST_CAR_REDUCER.FETCHING,
    })
    const response = await thunkDependencies.car.getListCar()
    dispatch({
      type: LIST_CAR_REDUCER.SET_DATA,
      payload: response.data,
    })
  } catch (error) {
    const message = error.message || UNKNOWN_ERROR
    dispatch({
      type: LIST_CAR_REDUCER.ERROR,
      payload: message,
    })
  }
}

export const filter = () => async (dispatch, getState, thunkDependencies) => {
  try {
    const { startDate, endDate, seats, price, brand, transmission, provinces } =
      getState().search
    const res = await thunkDependencies.car.searchCar({
      startDate: startDate ? startDate.substring(0, 16) : '',
      endDate: endDate ? endDate.substring(0, 16) : '',
      seats,
      brand,
      price,
      transmission,
      provinces
    })
    dispatch({
      type: LIST_CAR_REDUCER.SET_DATA,
      payload: res.data,
    })
  } catch (error) {
    const message = error.message || UNKNOWN_ERROR
    dispatch({
      type: FILTER_CAR_REDUCER.ERROR,
      payload: message,
    })
  }
}
export const setLocation =
  (location) => async (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: SEARCH_REDUCER.SET_LOCATION,
      payload: location,
    })
  }

export const setStartDate =
  (pickupDate) => async (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: SEARCH_REDUCER.SET_PICK_UP_DATE,
      payload: pickupDate,
    })
  }

export const setEndDate =
  (returnDate) => async (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: SEARCH_REDUCER.SET_RETURN_DATE,
      payload: returnDate,
    })
  }

export const setSeatsQuantityToSearch =
  (seats) => (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: SEARCH_REDUCER.SET_SEATS,
      payload: seats,
    })
  }
  
export const setBrandToSearch =
  (brand) => (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: SEARCH_REDUCER.SET_BRAND,
      payload: brand,
    })
  }
export const setInformation =
  (key, data) => async (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: BOOKING_INFORMATION_REDUCER.SET_DATA,
      payload: data,
      key,
    })
  }

export const setProvinces =
  (data) => async (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: SEARCH_REDUCER.SET_PROVINCES,
      payload: data,
    })
  }

export const resetFilter =
  () => async (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: SEARCH_REDUCER.RESET_SEARCH
    })
  }
export const fetchProvinces =
  () => async (dispatch, getState, thunkDependencies) => {
    try {
      dispatch({
        type: PROVINCES_REDUCER.FETCHING,
      })
      const response = await thunkDependencies.car.getProvinces()
      dispatch({
        type: PROVINCES_REDUCER.SET_PROVINCES,
        payload: response.data,
      })
    } catch (error) {
      const message = error.message || UNKNOWN_ERROR
      dispatch({
        type: PROVINCES_REDUCER.ERROR,
        payload: message,
      })
    }

  }
export const getSeats = () => async (dispatch, getState, thunkDependencies) => {
  const seats = await thunkDependencies.car.getSeats()
  dispatch({
    type: LIST_CAR_REDUCER.SET_SEATS,
    payload: seats.data,
  })
}
