/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from 'antd'
import { useEffect } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/pagination'
import './car.scss'
import '../../styles/index.scss'
import { Grid, Autoplay } from 'swiper'
import CardItem from '../../components/Card/Card'
import CardDetail from '../../components/Card/CardDetail'
import Button from '../../components/Button/Button'
import * as action from './action'
import { ROUTES } from '../../constant/ROUTES'

const CarContainer = ({
  fetch,
  detailCar,
  fetching,
  sameCars,
  setCarBooking,
}) => {
  const navigate = useNavigate()
  const { id } = useParams()
  useEffect(() => {
    id && fetch(id)
    id && setCarBooking(id)
  }, [id])
  const booking = () => {
    console.log('aaa')
  }
  return (
    <main>
      <Spin tip='Loading...' spinning={fetching} delay={500}>
        {detailCar && (
          <div className='car'>
            <div className='car_container'>
              <div className='car_info'>
                <div className='container'>
                  <CardDetail
                    brand={detailCar.brand}
                    key={detailCar.id}
                    image={detailCar.imagePath || '/image/hondacivic.png'}
                    name={detailCar.name}
                    price={detailCar.price}
                    seats={detailCar.seats}
                    transmission={detailCar.transmission}
                    desc={detailCar.desc}
                  />

                  <div className='car_info-button'>
                    <Button
                      onClick={() => navigate(-1)}
                      otherClass={'button_red'}
                      text={'Back'}
                    />
                    <Link to={ROUTES.FILLED}>
                      <Button otherClass={'button_primary'} text={'Book now'} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className='container'>
                <div className='car_suggest'>
                  <h1 className='text_heading'>Same price car model</h1>
                  <Swiper
                    slidesPerView={4}
                    grid={{
                      rows: 1,
                    }}
                    modules={[Grid, Autoplay]}
                    className='mySwiper'
                    loop={true}
                    autoplay={{ delay: 2000 }}
                  >
                    {sameCars.map((item) => (
                      <SwiperSlide>
                        <CardItem
                          id={item.id}
                          key={item.id}
                          image={item.image || '/image/audi.png'}
                          name={item.name}
                          price={item.price}
                          seats={item.seats}
                          brand={item.brand}
                          transmission={item.transmission}
                          onClick={booking}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        )}
      </Spin>
    </main>
  )
}

export function mapStateToProps(state) {
  return {
    detailCar: state.detailCar.data,
    sameCars: state.detailCar.sameCars,
    listCar: state.listCar.data,
    fetching: state.detailCar.fetching,
  }
}
export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...action,
    },
    dispatch
  )
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CarContainer
)
