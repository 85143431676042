import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import './input.scss'
import { useState } from 'react'

const Input = React.forwardRef(({
  label,
  onChange,
  id,
  value,
  type,
  placeholder,
  disabled,
  name,
  onKeyPress,
  required,
  error,
  touched,
  onFocusOut,
  otherClass,
  style,
  children

}, ref) => {
  const { t } = useTranslation()
  const languageLocal = window.localStorage.getItem('LANGUAGE') ? window.localStorage.getItem('LANGUAGE') : null;
  const [err, setErr] = useState()
  useEffect(() => {
    if (name === 'firstName' && languageLocal === 'us' && error) {
      setErr(error[name])
    }
    if (name === 'firstName' && languageLocal === 'vn' && error) {
      setErr('Vui lòng nhập Họ.')
    }
    if (name === 'firstName' && languageLocal === 'jp' && error) {
      setErr('氏を入力してください。')
    }
    if (name === 'lastName' && languageLocal === 'us' && error) {
      setErr(error[name])
    }
    if (name === 'lastName' && languageLocal === 'vn' && error ) {
      setErr('Vui lòng nhập Tên.')
    }
    if (name === 'lastName' && languageLocal === 'jp' && error) {
      setErr('名を入力してください。')
    }
    if (name === 'phone' && languageLocal === 'us' && error) {
      setErr(error[name])
    }
    if (name === 'phone' && languageLocal === 'vn' && error) {
      setErr('Vui lòng nhập Số điện thoại.')
    }
    if (name === 'phone' && languageLocal === 'jp' && error) {
      setErr('電話番号を入力してください。')
    }
    if (name === 'email' && languageLocal === 'us' && error) {
      setErr(error[name])
    }
    if (name === 'email' && languageLocal === 'vn' && error) {
      setErr('Vui lòng nhập Email.')
    }
    if (name === 'email' && languageLocal === 'jp' && error) {
      setErr('メールを入力してください。')
    }
    if (name === 'startLocation' && languageLocal === 'us' && error) {
      setErr(error[name])
    }
    if (name === 'startLocation' && languageLocal === 'vn' && error) {
      setErr('Vui lòng nhập Điểm đón.')
    }
    if (name === 'startLocation' && languageLocal === 'jp' && error) {
      setErr('出発先を入力してください。')
    }
    if (name === 'endLocation' && languageLocal === 'us' && error) {
      setErr(error[name])
    }
    if (name === 'endLocation' && languageLocal === 'vn' && error) {
      setErr('Vui lòng nhập Điểm đến.')
    }
    if (name === 'endLocation' && languageLocal === 'jp' && error) {
      setErr('行き先入力してください。')
    }

  }, [error, languageLocal])

  console.log('value', value)
  return (
    <div className={`inputItem ${otherClass}`} style={style}>
      <label className='text_m'>
        {`${t(label)}:`} {required && <span style={{ color: 'red' }}>*</span>}
      </label>
      <input
        type={type}
        onChange={onChange}
        id={id}
        value={value}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        onKeyPress={onKeyPress}
        ref={ref && ref}
      />
      {touched && touched[name] && error && error[name] && (
        <>
          {/* <div className='errorMessage'>{error[name]}</div> */}
          <div className='errorMessage'>{err}</div>
        </>
      )}
      {children}
    </div>
  )
})

Input.propTypes = {
  otherClass: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

Input.defaultProps = {
  otherClass: '',
  type: 'text',
  label: '',
  onchange: () => { },
  value: '',
}

export default Input
