/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { compose } from 'recompose'
import { useTranslation } from 'react-i18next'
import { usePlacesWidget } from 'react-google-autocomplete'
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Input from '../../components/Input/Input'
import { useLocation } from 'react-router-dom'
import Button from '../../components/Button/Button'
import Checkbox from '../../components/Checkbox/Checkbox'
import FilledInfo from '../../components/Filled/FilledInfo'
import FilledLocation from '../../components/Filled/FilledLocation'
import { BOOKING_INFORMATION_REDUCER } from '../../constant/constant'
import { ROUTES } from '../../constant/ROUTES'
import { OtherService } from '../../data'
import * as action from './action'
import './filled.scss'
import StepBooking from '../../components/Steps/Steps'
import AlertModal from '../../components/ContentModal/AlertModal'
import Modal from 'react-modal';

const googleApi = process.env.REACT_APP_GOOGLE_API
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    borderRadius: '20px',
    maxWidth: '80%',
  },
};

const Filled = ({
  setOtherService,
  setInformation,
  error,
  touched,
  submit,
  booking,
  redirect,
  setCarBooking,
  step,
  setStatusStep,
  clicked
}) => {
  const location = useLocation()
  const [visible, setVisible] = useState(false)
  const [dateDuplicate, setDateDuplicate] = useState(false)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const checkDuplicateLocation = () => {
    if (booking.startLocation === booking.endLocation) {
      setVisible(true)
      return
    }
    submit()
  }

  const closeModal = () => {
    setVisible(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (redirect && clicked) {
      setStatusStep('fill', 'finish')
      navigate(ROUTES.CONFIRM)
    } else if (clicked && !redirect) {
      setStatusStep('fill', 'error')
    }
    return () => {
      dispatch({
        type: BOOKING_INFORMATION_REDUCER.SET_REDIRECT,
        payload: false,
      })
    }
  }, [redirect, clicked])

  useEffect(() => {
    if (!booking.carId) {
      navigate('/cars')
    }
    console.log('booking', booking.startDate === booking.endDate)

    if (booking.startDate === booking.endDate) {
      console.log('asdasd')
      setDateDuplicate(true)
      setVisible(true)
    }
  }, [location])
  // useEffect(() => {
  //   if (location.state) {
  //     setCarBooking(location.state.id)
  //   } 
  //   console.log('location', location)
  // }, [location])
  // const { ref } = usePlacesWidget({
  //   apiKey: googleApi,
  //   onPlaceSelected: (place) => {
  //     const arrayAddress = place.formatted_address.split(',')
  //     console.log('arrayAddress', arrayAddress)
  //     if (arrayAddress[1].trim().substring(0, 6) === 'Phường') {
  //       setInformation('ward', arrayAddress[1])
  //     }
  //     setInformation('startLocation', place.formatted_address)
  //     if (place.address_components.length === 5) {
  //       setInformation('district', place.address_components[2].long_name)
  //       setInformation('city', place.address_components[3].long_name)
  //     } else if (place.address_components.length === 4) {
  //       setInformation('district', place.address_components[1].long_name)
  //       setInformation('city', place.address_components[2].long_name)
  //     }
  //   },
  //   options: {
  //     types: [],
  //     componentRestrictions: { country: 'vn' },
  //   },
  // })
  // const endsRef = usePlacesWidget({
  //   apiKey: googleApi,
  //   onPlaceSelected: (place) => {
  //     setInformation('endLocation', place.formatted_address)
  //   },
  //   options: {
  //     types: [],
  //     componentRestrictions: { country: 'vn' },
  //   },
  // }).ref

  // const {
  //   placesService,
  //   placePredictions,
  //   getPlacePredictions,
  //   isPlacePredictionsLoading,
  // } = usePlacesService({
  //   apiKey: googleApi,
  //   debounce: 3000
  // });

  // useEffect(() => {
  //   // fetch place details for the first element in placePredictions array
  //   if (placePredictions.length)
  //     // eslint-disable-next-line no-undef
  //     service.placesService?.getDetails(
  //       {
  //         placeId: placePredictions[0].place_id,
  //       },
  //       // eslint-disable-next-line no-undef
  //       (placeDetails) => savePlaceDetailsToState(placeDetails)
  //     );
  // }, [placePredictions]);
  return (
    <main>
      <div className='filled'>
        <div className='container'>
          <StepBooking
            current={0}
            status={step}
          />
          <div className='filled_container'>
            <h1 className='filled_heading text_heading'>
              {t('Fill in information')}
            </h1>
            <div className='filled_form'>
              <FilledInfo
                // refs={ref}
                // endsRef={endsRef}
                booking={booking}
                error={error}
                touched={touched}
                setInformation={setInformation}
              />
            </div>
            <div className='filled_service'>
              <h3 className='filled_service-heading text_l'>
                {t('Other services')}
              </h3>
              <div className='filled_service-group'>
                {OtherService.map((item) => (
                  <Checkbox
                    onChange={(e) =>
                      setOtherService(item.key, e.target.checked)
                    }
                    label={item.name}
                    key={item.id}
                    name='otherService'
                    id={item.id}
                    value={item.id}
                    checked={booking.otherService && booking.otherService[item.key]}
                  />
                ))}
              </div>
              <p style={{ marginTop: 20 }} className='text_m'>{t('Other services will be billed separately.')}</p>
            </div>
            <div className='filled_button'>
              <Link to={ROUTES.CARS}>
                <Button text='Back' otherClass='button_primary gradient_red' />
              </Link>
              <Button
                onClick={checkDuplicateLocation}
                text='SEND'
                type='submit'
                otherClass='button_primary gradient_blue'
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={visible}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <AlertModal onClose={() => setVisible(false)}
          content={t('This distance is a bit of a walk. Would you like to choose a further destination?')}
        />
      </Modal>
    </main>
  )
}

export function mapStateToProps(state) {
  return {
    location: state.search.location,
    seats: state.search.seats,
    error: state.booking.error,
    touched: state.booking.touched,
    booking: state.booking.data,
    redirect: state.booking.redirect,
    step: state.booking.stepStatus.fill,
    clicked: state.booking.clicked,

  }
}
export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...action,
    },
    dispatch
  )
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(Filled)
