import { Link } from 'react-router-dom'
import { motion, } from "framer-motion";
import { faCarSide } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './cardIntro.scss'
import { useTranslation } from 'react-i18next'
const CardIntro = ({
  image,
  onClick,
  label,
}) => {
  const { t } = useTranslation()

  return (
    <motion.div
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 0.95 }}
      transition={{ type: "spring", stiffness: 300, damping: 20 }}
      className="cardIntro"
      onClick={onClick}>
      <div className="cardIntro_image">
        <img src={image} alt={label} />
      </div>
      <div className="cardIntro_content">
        <p className="cardIntro_label">{label}</p>
        <p className="cardIntro_sub">{t('Find Cars')}
          <div className="cardIntro_icon">
            <FontAwesomeIcon icon={faCarSide} />
          </div>
        </p>
      </div>
    </motion.div>
  )
}

export default CardIntro
