/** @format */

import { listCarReducer, listProvinceReducer } from '../container/HomeContainer/reducer'
import { detailCarReducer } from '../container/CarContainer/reducer'
import { searchReducer } from './searchReducer'
import { brandReducer } from './brandReducer'
import { bookingReducer } from '../container/FilledContainer/bookingInfoReducer'
import { bookingSuccessReducer } from '../container/SuccessContainer/reducer'

const rootReducer = {
  listCar: listCarReducer,
  detailCar: detailCarReducer,
  search: searchReducer,
  brand: brandReducer,
  booking: bookingReducer,
  listProvince: listProvinceReducer,
  infoSuccess: bookingSuccessReducer
}

export default rootReducer
