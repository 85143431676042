import React from 'react';
import { useTranslation } from 'react-i18next'

const AlertModal = ({ onClose, content }) => {

  const { t } = useTranslation()

  return (
    <div className="modal-container">
      <div className="modal">
        <h1 className="modal__title">{t('Notification')}</h1>
        <p className="modal__text">{content}</p>
        <button className="modal__close" onClick={onClose}></button>
      </div>
    </div>
  );
};

export default AlertModal;