import _ from 'lodash'
import validator from 'validator'
import { BOOKING_INFORMATION_REDUCER } from '../../constant/constant'

const empty = (value) => value === '' || value === null || value === undefined

const validateField = (field, value, form, fieldRules) => {
  let message = ''
  let valid = true
  _.forEach(fieldRules, (rule) => {
    const ruleName = rule[0]
    let errorMessage = rule[1]
    if (typeof ruleName === 'string') {
      if (ruleName === 'required' && empty(value)) {
        valid = false
      }
    } else if (typeof ruleName === 'function') {
      valid = ruleName(value, form)
    }
    if (field === 'email' && value && validator.isEmail(value) === false) {
      valid = false
      errorMessage = 'Not a email'
    }
    if (
      field === 'phone' &&
      value &&
      validator.isMobilePhone(value, 'vi-VN') === false
    ) {
      valid = false
      errorMessage = 'Not a phone number'
    }
    if (!valid) {
      message = errorMessage
      return false
    }
    return true
  })

  return message
}

const validateFields = (form, formRule) => {
  const error = {}
  const touched = {}
  let redirect = false
  let clicked = true

  _.forIn(formRule, (rules, field) => {
    const value = form[field]
    error[field] = validateField(field, value, form, rules)
    if (error[field]) {
      touched[field] = true
    }
  })
  if (!form.isValidated) {
    if (
      _.values(error).filter((i) => i.length).length === 0 &&
      _.values(error).filter((i) => i === false).length === 0
    ) {
      redirect = true

    }
  }
  return [error, touched, redirect, clicked]
}

const initialValue = {
  data: {
    carId: 0,
    startDate: '',
    endDate: '',
    startLocation: '',
    firstName: '',
    lastName: '',
    phone: '',
    city: '',
    district: '',
    ward: '',
    email: '',
    noteLocation: '',
    other: '',
    endLocation: '',
    otherService: {
      souvenir: false,
      hotelReservation: false,
      golfReservation: false,
      translation: false,
      massage: false,
      japaneseKaraoke: false,
    },
  },
  touched: {},
  error: {},
  redirect: false,
  clicked: false,
  stepStatus : {
    fill: 'process',
    confirm: 'wait',
    success: 'wait'
  }
}



const rules = {
  startDate: [['required', 'Start Date is required']],
  endDate: [['required', 'Please enter your Destination.']],
  firstName: [['required', 'Please enter your First Name.']],
  lastName: [['required', 'Please enter your Last Name.']],
  phone: [['required', ' Please enter your Phone Number.']],
  email: [['required', 'Please enter your Email.']],
  startLocation: [['required', 'Please enter your Pick-up Place.']],
  endLocation: [['required', 'Destination is required']],
}

export const bookingReducer = (state = initialValue, action) => {
  switch (action.type) {
    case BOOKING_INFORMATION_REDUCER.SET_DATA:
      return {
        ...state,
        data: { ...state.data, [action.key]: action.payload },
        error: {
          ...state.error,
          [action.key]: validateField(
            action.key,
            action.payload,
            state.data,
            rules[action.key]
          ),
        },
        touched: { ...state.touched, [action.key]: true },
      }
    case BOOKING_INFORMATION_REDUCER.SET_OTHER_SERVICE:
      return {
        ...state,
        data: {
          ...state.data,
          otherService: {
            ...state.data.otherService,
            [action.key]: action.payload,
          },
        },
      }
    case BOOKING_INFORMATION_REDUCER.SET_TOUCHED:
      return {
        ...state,
        touched: { ...state.touched, [action.key]: action.payload },
        error: { ...state.error, [action.key]: action.message },
      }

    case BOOKING_INFORMATION_REDUCER.VALIDATE_ALL:
      const [error, touched, redirect, clicked] = validateFields(state.data, rules)
      return {
        ...state,
        error: { ...error },
        touched: { ...touched },
        redirect: redirect,
        clicked: clicked,
      }
    case BOOKING_INFORMATION_REDUCER.SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload,
        clicked: action.payload
      }
    case BOOKING_INFORMATION_REDUCER.SET_STEP_STATUS:
      return {
        ...state,
        stepStatus : {
          ...state.stepStatus,
          [action.key] : action.value
        }
      }
    default:
      return state
  }
}
