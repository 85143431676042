import axios from 'axios'
import { handleError } from '../helpers/errorHandler'
axios.defaults.baseURL = 'https://bookvipcar.vjitp.com/' // process.env.REACT_APP_API_URL;

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
  crossorigin: true,
}

export const request = async (url, options) => {
  const requestOptions = {
    ...defaultOptions,
    ...options,
  }

  try {
    const response = await axios({
      url,
      ...requestOptions,
    })
    return response.data
  } catch (e) {
    const { status } = e.response
    const { data } = e.response

    return handleError(status, data)
  }
}
