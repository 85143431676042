/** @format */

import { forIn } from 'lodash'
// import { logout } from '../container/AuthContainer/AuthAction'

export const thunkWithApiHandlerMiddleware = (apis) => {
  return (store) => {
    const { dispatch, getState } = store

    // Add UNAUTHORIZED handler to apis
    const updatedApis = {}

    forIn(apis, (api, key) => {
      const newApi = {}
      forIn(api, (method, name) => {
        newApi[name] = async (...args) => {
          try {
            return await method(...args)
          } catch (error) {
            if (error.unauthorized) {
              // dispatch(logout())
              console.log('error')
            }
            throw error
          }
        }
      })
      updatedApis[key] = newApi
    })

    return (next) => {
      return (action) => {
        if (typeof action === 'function') {
          return action(dispatch, getState, updatedApis)
        }
        return next(action)
      }
    }
  }
}
