import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../constant/ROUTES'
import './menu.scss'

const Menu = () => {
  const { t } = useTranslation()
  return (
    <div className='menu'>
      <ul className='menu_list'>
        <li className='menu_item '>
          <NavLink
            className={({ isActive }) => (isActive ? 'menu_active' : undefined)}
            to={ROUTES.HOME}
          >
            {t('Home')}
          </NavLink>
        </li>
        <li className='menu_item'>
          <NavLink
            className={({ isActive }) => (isActive ? 'menu_active' : undefined)}
            to={ROUTES.CARS}
          >
            {t('Cars')}
          </NavLink>
        </li>
        {/* <li className='menu_item'>
          <NavLink
            className={({ isActive }) => (isActive ? 'menu_active' : undefined)}
            to={ROUTES.CONTACT}
          >
            {t('Contact')}
          </NavLink>
        </li> */}
        <li className='menu_item'>
          <NavLink
            className={({ isActive }) => (isActive ? 'menu_active' : undefined)}
            to={ROUTES.RULES}
          >
            {t('Rules')}
          </NavLink>
        </li>
      </ul>
    </div>
  )
}

export default Menu
