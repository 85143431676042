export const ROUTES = {
  HOME: '/',
  CARS: '/cars',
  CAR: '/car/:id/',
  CAR_ROOT: '/car',
  SERVICE: '/service',
  CONTACT: '/contact',
  RULES: '/rules',
  FILLED: '/filled',
  CONFIRM: '/confirm',
  SUCCESS: '/success/:id',
}
