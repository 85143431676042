import { combineReducers } from 'redux'
import { BOOKING_REDUCER } from '../../constant/constant'

const initialValue = {
  data: {}
}

export const bookingSuccessReducer = (state = initialValue, action) => {
  switch (action.type) {
    case BOOKING_REDUCER.SET_DETAIL:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}


