import React from 'react';
import { Steps } from 'antd';
import './steps.scss'
import { useTranslation } from 'react-i18next';

const { Step } = Steps;

const StepBooking = ({ current, status, status1, status2, status3 }) => {
 const { t } = useTranslation()

 return (
  <div className="stepBooking">
   <Steps current={current} status={status} size="small" labelPlacement="vertical">
    <Step title={t('Fill In Information')} status={status1} />
    <Step title={t('Confirmation and payment')} status={status2}/>
    <Step title={t('Success')} status={status3}/>
    {/* <Step title="Waiting" status={status3}  description="This is a description." /> */}
   </Steps>
  </div>
 );
};

export default StepBooking;