import { combineReducers } from 'redux'
import { LIST_CAR_REDUCER, SEARCH_REDUCER, PROVINCES_REDUCER } from '../../constant/constant'

const initialValue = {
  fetching: false,
  data: [],
  seats: [],
  error: '',
}

const provinces = {
  data: [],
  fetching: false,
  error: ''
}
export const listCarReducer = (state = initialValue, action) => {
  switch (action.type) {
    case LIST_CAR_REDUCER.SET_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case LIST_CAR_REDUCER.SET_SEATS:
      return {
        ...state,
        seats: action.payload,
      }
    case LIST_CAR_REDUCER.FETCHING:
      return {
        ...state,
        fetching: true,
      }
    case LIST_CAR_REDUCER.FETCH_DONE:
      return {
        ...state,
        fetching: false,
      }
    case LIST_CAR_REDUCER.ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case LIST_CAR_REDUCER.RESET_DATA:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export const listProvinceReducer = (state = provinces, action) => {
  switch (action.type) {
    case PROVINCES_REDUCER.SET_PROVINCES:
      return {
        ...state,
        data: action.payload,
      }
    case PROVINCES_REDUCER.FETCHING:
      return {
        ...state,
        fetching: true,
      }
    case PROVINCES_REDUCER.ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default combineReducers({
  listCar: listCarReducer,
  listProvince: listProvinceReducer
})
