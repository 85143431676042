import {
  FILTER_CAR_REDUCER,
  SEARCH_REDUCER,
  LIST_CAR_REDUCER,
  BRAND_REDUCER,
  BOOKING_INFORMATION_REDUCER,
  UNKNOWN_ERROR
} from '../../constant/constant'

export const fetching =
  (fetching) => (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: SEARCH_REDUCER.FETCHING,
      payload: fetching
    })
  }


export const filter = () => async (dispatch, getState, thunkDependencies) => {
  try {
    const { startDate, endDate, seats, price, brand, provinces } =
      getState().search
    dispatch(fetching(true))
    const res = await thunkDependencies.car.searchCar({
      startDate: startDate ? startDate.substring(0, 16) : '',
      endDate: endDate ? endDate.substring(0, 16) : '',
      seats,
      brand,
      price,
      provinces
    })
    dispatch({
      type: LIST_CAR_REDUCER.SET_DATA,
      payload: res.data,
    })
    dispatch(fetching(false))
  } catch (error) {
    const message = error.message || UNKNOWN_ERROR
    dispatch({
      type: FILTER_CAR_REDUCER.ERROR,
      payload: message,
    })
  }
}

export const getBrand = () => async (dispatch, getState, thunkDependencies) => {
  const res = await thunkDependencies.car.getBrand()
  dispatch({
    type: BRAND_REDUCER.SET_BRAND,
    payload: res.data,
  })
}
export const getSeats = () => async (dispatch, getState, thunkDependencies) => {
  const seats = await thunkDependencies.car.getSeats()
  dispatch({
    type: LIST_CAR_REDUCER.SET_SEATS,
    payload: seats.data,
  })
}
export const setSeatsQuantityToSearch =
  (seats) => (dispatch, getState, thunkDependencies) => {
    const seat = getState().search.seats
    const isExisted = seat.includes(seats)
    if (isExisted) {
      dispatch({
        type: SEARCH_REDUCER.SET_SEATS,
        payload: seat.filter((value) => value !== seats),
      })
    } else {
      dispatch({
        type: SEARCH_REDUCER.SET_SEATS,
        payload: [...seat, seats],
      })
    }
  }

export const setPriceToSearch =
  (price) => (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: SEARCH_REDUCER.SET_PRICE,
      payload: price,
    })
  }

export const resetSeats =
  () => (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: SEARCH_REDUCER.RESET_SEATS,
    })
  }

export const setBrandToSearch =
  (brand) => (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: SEARCH_REDUCER.SET_BRAND,
      payload: brand,
    })
  }

export const setTransmissionToSearch =
  (transmission) => (dispatch, getState, thunkDependencies) => {
    dispatch({
      type: SEARCH_REDUCER.SET_TRANSMISSION,
      payload: transmission,
    })
  }


