/* eslint-disable import/no-anonymous-default-export */
import { request } from './request'

export const getListCar = () => {
  return request('api/v1/listCar', {
    method: 'get',
  })
}

export const getDetailCar = (id) => {
  return request(`api/v1/car/${id}`, {
    method: 'get',
  })
}

export const getSameCars = (id) => {
  return request(`api/v1/sameCars/${id}`, {
    method: 'get',
  })
}

export const getSeats = () => {
  return request(`api/v1/seatConditions`, {
    method: 'get',
  })
}

export const getProvinces = () => {
  return request(`api/v1/getProvinces`, {
    method: 'get',
  })
}

export const searchCar = (data) => {
  return request(`api/v1/searchCar`, {
    method: 'post',
    data,
  })
}
export const getBrand = () => {
  return request(`api/v1/brandConditions`, {
    method: 'get',
  })
}
export const booking = (data) => {
  return request(`api/v1/saveBooking`, {
    method: 'post',
    data,
  })
}

export const getBooking = (id) => {
  return request(`api/v1/getBooking/${id}`, {
    method: 'get',
  })
}

export const updateBookingPaid = params => {
  return request(`api/v1/updateBooking`, {
    method: 'put',
    params
  })
}

export const paymentAgain = (params) => {
  return request(`api/v1/paymentAgain`, {
    method: 'get',
    params
  })
}

export const cancelPayment = (id) => {
  return request(`api/v1/deleteBooking/${id}`, {
    method: 'delete',
  })
}

export const credit = () => {
  return request(
    `https://sandbox.vnpayment.vn/paymentv2/vpcpay.html?vnp_Amount=1806000&vnp_Command=pay&vnp_CreateDate=20210801153333&vnp_CurrCode=VND&vnp_IpAddr=127.0.0.1&vnp_Locale=vn&vnp_OrderInfo=Thanh+toan+don+hang+%3A5&vnp_OrderType=other&vnp_ReturnUrl=https%3A%2F%2Fdomainmerchant.vn%2FReturnUrl&vnp_TmnCode=DEMOV210&vnp_TxnRef=5&vnp_Version=2.1.0&vnp_SecureHash=3e0d61a0c0534b2e36680b3f7277743e8784cc4e1d68fa7d276e79c23be7d6318d338b477910a27992f5057bb1582bd44bd82ae8009ffaf6d141219218625c42`,
    {
      method: 'get',
    }
  )
}
export default {
  getListCar,
  getDetailCar,
  getSameCars,
  getSeats,
  searchCar,
  getBrand,
  booking,
  credit,
  getProvinces,
  getBooking,
  updateBookingPaid,
  paymentAgain,
  cancelPayment
}
