import React, { useState, useEffect } from 'react'
import moment from 'moment'
import lodash from 'lodash'
import Input from '../Input/Input'
import Textarea from '../Textarea/Textarea'
import SelectDate from '../SelectDate/SelectDate'
import { usePlacesWidget } from 'react-google-autocomplete'
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import './FilledInfo.scss'
import FilledLocation from './FilledLocation'
import { Select } from 'antd';
import { useRef } from 'react'


const { Option } = Select;
const googleApi = process.env.REACT_APP_GOOGLE_API

const style = {
  backgroundColor: 'red'
}
const FilledInfo = ({
  style,
  disabled,
  setInformation,
  booking,
  booking: {
    startLocation,
    city,
    district,
    ward,
    noteLocation,
    endLocation,
  },
  error,
  touched,
  refs,
  endsRef,
}) => {

  const [locationList, setLocationList] = useState([])
  const [locationList2, setLocationList2] = useState([])
  const [checkUpdate, setCheckUpdate] = useState({
    start: 'new',
    end: 'new'
  })
  const startRef = useRef()
  const endRef = useRef()
  console.log('booking', booking)
  const onFocusOut = () => {
    setLocationList([])
    setLocationList2([])
  }
  useEffect(() => {
    window.addEventListener('click', onFocusOut)
    return () => {
      window.removeEventListener('click', onFocusOut)
    }
  }, [locationList])
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: googleApi,
    debounce: 800
  });


  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (booking.startLocation && checkUpdate.start === 'update') {
      if (placePredictions.length > 0) {
        setLocationList(placePredictions.map(location => {
          return {
            location: location.description,
            place_id: location.place_id
          }
        }))
      }
      return
    }
    if (booking.endLocation && checkUpdate.end === 'update') {
      if (placePredictions.length > 0) {
        setLocationList2(placePredictions.map(location => {
          return {
            location: location.description,
            place_id: location.place_id
          }
        }))
      }
      return
    }
  }, [placePredictions]);

  useEffect(() => {
    if (!booking.startLocation) {
      setLocationList([])
    }
    if (checkUpdate.start !== 'update') {
      setCheckUpdate(prev => {
        return {
          end: 'new',
          start: 'update'
        }
      })
    }

  }, [booking.startLocation])

  useEffect(() => {
    if (!booking.endLocation) {
      setLocationList2([])
    }
    if (checkUpdate.end !== 'update') {
      setCheckUpdate(prev => {
        return {
          start: 'new',
          end: 'update'
        }
      })
    }

  }, [booking.endLocation])

  return (
    <div className={'filledInfo'}>
      <div className='filledInfo_date'>
        <SelectDate
          touched={touched}
          error={error}
          disabledDate={(current) =>
            current && current > moment(booking.endDate)
          }
          defaultValue={booking.startDate ? moment(booking.startDate) : null}
          label='Pick-up Date'
          name='startDate'
          disabled={disabled}
          onOk={(value) =>
            setInformation('startDate', value.format('YYYY-MM-DD HH:mm:ss'))
          }
        />
        <SelectDate
          touched={touched}
          error={error}
          disabledDate={(current) =>
            current && current < moment(booking.startDate)
          }
          defaultValue={booking.endDate ? moment(booking.endDate) : null}
          label='Drop-off Date'
          name='endDate'
          disabled={disabled}
          onOk={(value) =>
            setInformation('endDate', value.format('YYYY-MM-DD HH:mm:ss'))
          }
        />
      </div>
      <Input
        error={error}
        touched={touched}
        value={startLocation}
        onChange={(value) => {
          setInformation('startLocation', value.target.value)
          getPlacePredictions({ input: value.target.value });
        }}
        required
        disabled={disabled}
        label='Pick-up Place'
        style={{ position: 'relative' }}
        name='startLocation'
        ref={startRef}
      >
        {locationList.length > 0 &&
          <ul>
            {locationList.map((item) => (
              <li onClick={() => {
                setInformation('startLocation', item.location)
                setLocationList([])
              }
              }
                key={item.place_id}>{item.location}</li>)
            )}
          </ul>
        }
      </Input>
      <Input
        error={error}
        touched={touched}
        onChange={(value) => {
          setInformation('endLocation', value.target.value)
          getPlacePredictions({ input: value.target.value });
        }}
        value={endLocation}
        required
        disabled={disabled}
        label='Where To'
        name={'endLocation'}
        refs={endRef}
      >
        {locationList2.length > 0 &&
          <ul>
            {locationList2.map((item) => (
              <li onClick={() => {
                setInformation('endLocation', item.location)
                setLocationList2([])
              }
              }
                key={item.place_id}>{item.location}</li>)
            )}
          </ul>
        }
      </Input>
      <Input
        error={error}
        touched={touched}
        onChange={(value) => setInformation('noteLocation', value.target.value)}
        value={noteLocation}
        disabled={disabled}
        label='Location Notes'
        name='noteLocation' style={{ width: '100%' }}
      />
      <Input
        touched={touched}
        error={error}
        onChange={(value) => setInformation('firstName', value.target.value)}
        required
        disabled={disabled}
        label='First Name'
        name='firstName'
        value={booking ? booking.firstName : ''}
      />
      <Input
        touched={touched}
        error={error}
        onChange={(value) => setInformation('lastName', value.target.value)}
        required
        disabled={disabled}
        label='Last Name'
        name='lastName'
        value={booking ? booking.lastName : ''}
      />
      <Input
        touched={touched}
        error={error}
        onChange={(value) => setInformation('phone', value.target.value)}
        required
        disabled={disabled}
        label='Phone Number'
        name='phone'
        value={booking ? booking.phone : ''}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault()
          }
        }}
      />
      <Input
        error={error}
        touched={touched}
        onChange={(value) => setInformation('email', value.target.value)}
        required
        disabled={disabled}
        label='Email'
        type='email'
        name='email'
        value={booking ? booking.email : ''}
      />
      <Textarea
        touched={touched}
        error={error}
        onChange={(value) =>
          setInformation('other', value.target.value)
        }
        value={booking ? booking.other : ''}
        disabled={disabled}
        label='More Notes'
        style={{ marginTop: 20 }}
        name='other'
      />
    </div>
  )
}

export default FilledInfo
